import React from "react";
// import { useNavigate } from "react-router-dom";
import useCustomFetchQuery from "../../lib/Hooks/useCustomFecthQuery";
import { Container } from "reactstrap";
import ManagerReviewTable from "../../components/Pages/ReviewForm/Manager/ManagerReviewTable";

const ManagerReviewForm = () => {
  // const Navigate = useNavigate();
  const obj = JSON.parse(localStorage.getItem("authHC"));
  const token = obj?.token;
  const organizationID = obj?.data?.data?.organizationId;
  const departmentIDs = obj?.data?.data?.departmentId;
  const firstName = obj?.data?.data?.firstName;
  const lastName = obj?.data?.data?.lastName;
  const userFullName = `${firstName} ${lastName}`;

  const {
    data: departmentReviews,
    error,
    isLoading,
  } = useCustomFetchQuery(
    ["DepartmentReviews", organizationID],
    `${process.env.REACT_APP_APIKEY}assessments/department/${departmentIDs[0]?._id}`,
    token,
    { enabled: !!organizationID }
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <ManagerReviewTable
            departmentReviews={departmentReviews}
            organizationID={organizationID}
            departmentID={departmentIDs[0]?._id}
            userFullName={userFullName}
            token={token}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManagerReviewForm;
