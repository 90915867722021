import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import AdminDashboard from "./Admin/Admin";
import ManagerDashboard from "./Manager/Manager";
import EmployeeDashboard from "./Employee/Employee";
import { getFormattedDate } from "../../lib/utils/getTodayDate";
import LoaderDashboard from "../../components/Loader/LoaderDashboard";

const Dashboard = () => {
  document.title = "Dashboard | Kago Human Capital";
  const obj = JSON.parse(localStorage.getItem("authHC"));
  const token = obj?.token;
  const userID = obj?.data?.data?.id;
  const firstName = obj?.data?.data?.firstName;
  const lastName = obj?.data?.data?.lastName;
  const userFullName = `${firstName} ${lastName}`;
  const role = obj?.data?.data?.roles[0];
  const organizationID = obj?.data?.data?.organizationId;
  const departmentIDs = obj?.data?.data?.departmentId;
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const todayDate = getFormattedDate();

  useEffect(() => {
    // Simulate loading data
    const fetchData = async () => {
      // Simulate a delay for loading (e.g., API call)
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false); // Set loading to false after data is loaded
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="w-100">
            {loading ? (
              <LoaderDashboard />
            ) : role?.type === "Admin" ? (
              <AdminDashboard
                Navigate={Navigate}
                token={token}
                organizationID={organizationID}
              />
            ) : role?.type === "Manager" ? (
              <ManagerDashboard
                Navigate={Navigate}
                userID={userID}
                firstName={firstName}
                userFullName={userFullName}
                token={token}
                organizationID={organizationID}
                departmentID={departmentIDs[0]?._id}
                todayDate={todayDate}
              />
            ) : role?.type === "Employee" ||
              role?.type === "Intern" ||
              role?.type === "Learner" ? (
              <EmployeeDashboard
                userID={userID}
                token={token}
                organizationID={organizationID}
                departmentID={departmentIDs}
                todayDate={todayDate}
              />
            ) : null}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
