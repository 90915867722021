import React, { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import usePost from "../../../../lib/Hooks/usePost";
import useCustomMutation from "../../../../lib/Hooks/useCustomMutation";
import { Row, Col } from "reactstrap";
import Select from "react-select";
import { countryList } from "../../../../lib/utils/Countries";
// import ProfilePicture from "../Profile/ProfilePicture";
import PhotoPlaceholder from "../../../Common/Photo/PhotoPlaceholder";

import ButtonSubmit from "../../../Common/Buttons/ButtonSubmit";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#33A6CD" : "white",
    color: state.isFocused ? "white" : "black",
  }),
  control: (provided) => ({
    ...provided,
    borderColor: "#ccc",
    "&:hover": {
      borderColor: "#33A6CD",
    },
  }),
};

function EmployeeForm({
  token,
  organizationID,
  setIsAddProfile,
  organizationDetails,
  departmentDetails,
}) {
  const queryClient = useQueryClient();
  const [inputs, setInputs] = useState({});
  const key = ["AllEmployees", organizationID];
  const { status, execute, pending } = usePost();
  const mutation = useCustomMutation(key);

  const handleChange = (eventOrName, valueOrUndefined) => {
    if (typeof eventOrName === "string") {
      const name = eventOrName;
      const value = valueOrUndefined;
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    } else {
      const { name, value } = eventOrName.target;
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    }
  };

  const toggleSaveChanges = (e) => {
    e.preventDefault();
    const Method = "POST",
      url = `auth/employee/${organizationID}/${inputs?.department}`;
    const raw = JSON.stringify({
      employeeNumber: inputs.employeeNumber,
      firstName: inputs.firstName,
      lastName: inputs.lastName,
      email: inputs.email,
      phoneNumber: inputs.phoneNumber,
      roles: inputs.roles[0],
      initials: inputs.initials,
      dateOfBirth: inputs.dateOfBirth,
      identificationNumber: inputs.identificationNumber,
      country: inputs.country,
      taxNumber: inputs.taxNumber,
      physicalAddress: inputs.physicalAddress,
      unitNumber: inputs.unitNumber,
      complex: inputs.complex,
      suburb: inputs.suburb,
      city: inputs.city,
      postalCode: inputs.postalCode,
    });
    execute(url, raw, Method, "Employee successfully added", token, null);
    // const data = {
    //   endPoint: url,
    //   bodyContent: raw,
    //   Method: Method,
    //   displayMessage: "Employee successfully added",
    //   token: token,
    // };
    // mutation.mutate(data);
  };

  useEffect(() => {
    if (status) {
      queryClient.invalidateQueries(key);
      setIsAddProfile(false);
    }
  }, [status]);

  // console.log("inputs...", JSON.stringify(inputs, null, 2));

  return (
    <>
      <div className="w-100 d-flex justify-content-center p-3">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          onSubmit={toggleSaveChanges}
        >
          <Row>
            <Col md={12}>
              <div className="d-flex mb-4 justify-content-center">
                <PhotoPlaceholder
                  iconSize={80}
                  iconColor="#fff"
                  imgHeight={100}
                  imgWidth={100}
                  imgBorder={20}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6} lg={4}>
              <label>
                Department <span className="text-danger">*</span>
              </label>
              <Select
                options={departmentDetails?.map((item) => ({
                  value: item?._id,
                  label: item?.name,
                }))}
                styles={customSelectStyles}
                placeholder="Select a department"
                onChange={(option) => handleChange("department", option?.value)}
              />
            </Col>
            <Col md={6} lg={4}>
              <label>
                Roles <span className="text-danger">*</span>
              </label>
              <Select
                options={organizationDetails?.roles?.map((role) => ({
                  value: JSON.stringify({
                    type: role?.name,
                    period: role?.period,
                  }),
                  label: role?.name,
                }))}
                styles={customSelectStyles}
                placeholder="Select a role"
                onChange={(option) =>
                  handleChange("roles", [JSON.parse(option?.value)])
                }
              />
            </Col>

            <Col md={6} lg={4} className="mt-3">
              <label>
                Employee number <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="employeeNumber"
                value={inputs.employeeNumber}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>

            <Col md={6} lg={4} className="mt-3">
              <label>
                Initials <span className=" text-danger">*</span>
              </label>
              <input
                type="text"
                name="initials"
                value={inputs.initials}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>

            <Col md={6} lg={4}>
              <label>
                First name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="firstName"
                value={inputs.firstName}
                placeholder="Enter First name"
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>
            <Col md={6} lg={4}>
              <label>
                Last name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="lastName"
                value={inputs.lastName}
                placeholder="Enter Last name"
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>
            <Col md={6} lg={4}>
              <label>
                Date of birth <span className="text-danger">*</span>
              </label>
              <input
                type="date"
                name="dateOfBirth"
                value={inputs?.dateOfBirth}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>
            <Col md={6} lg={4}>
              <label>
                ID number / Passport <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="identificationNumber"
                value={inputs?.identificationNumber}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>

            <Col md={6} lg={4}>
              <label>
                Country of Origin <span className="text-danger">*</span>
              </label>
              <Select
                options={countryList.map((country) => ({
                  value: country,
                  label: country,
                }))}
                styles={customSelectStyles}
                placeholder="Select..."
                onChange={(option) => handleChange("country", option.value)}
              />
            </Col>
            <Col md={6} lg={4}>
              <label>
                Tax number
                {/* <span className="text-danger">*</span> */}
              </label>
              <input
                type="text"
                name="taxNumber"
                value={inputs?.taxNumber}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>
            <Col md={6} lg={4}>
              <label>
                Email <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="email"
                value={inputs?.email}
                placeholder="Enter Email"
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>
            <Col md={6} lg={4}>
              <label>
                Phone Number <span className="text-danger">*</span>
              </label>
              <div className="phone-input-container">
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="ZA"
                  value={inputs.phoneNumber ? inputs.phoneNumber : null}
                  onChange={(value) =>
                    setInputs({ ...inputs, phoneNumber: value })
                  }
                  className="custom-phone-input"
                />
              </div>
            </Col>
            <Col md={12}>
              <h3 className="my-3 d-flex justify-content-center">
                Residential address
              </h3>
            </Col>
            <Col md={6} lg={4}>
              <label>
                Physical address <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="physicalAddress"
                value={inputs?.physicalAddress}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>
            <Col md={6} lg={4}>
              <label>Unit number</label>
              <input
                type="text"
                name="unitNumber"
                value={inputs?.unitNumber}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
              />
            </Col>
            <Col md={6} lg={4}>
              <label>Complex</label>
              <input
                type="text"
                name="complex"
                value={inputs?.complex}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
              />
            </Col>
            <Col md={6} lg={4}>
              <label>
                Suburb / district <span className="text-danger">*</span>
              </label>

              <input
                type="text"
                name="suburb"
                value={inputs?.suburb}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>
            <Col md={6} lg={4}>
              <label>
                City / Town <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="city"
                value={inputs?.city}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
              />
            </Col>
            <Col md={6} lg={4}>
              <label>
                Postal code <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="postalCode"
                value={inputs?.postalCode}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>

            <Col md={6} lg={4}>
              <label>
                Country <span className="text-danger">*</span>
              </label>
              <Select
                options={countryList.map((country) => ({
                  value: country,
                  label: country,
                }))}
                styles={customSelectStyles}
                placeholder="Select..."
                onChange={(option) => handleChange("country", option.value)}
              />
            </Col>

            <div className="mb-3"></div>
            <div className="w-100 d-flex justify-content-center">
              <ButtonSubmit
                Title="Submit"
                ColorText="white"
                BorderColor="#33A6CD"
                BackgroundColor="#33A6CD"
                borderRadius="25px"
                handleOnclick={toggleSaveChanges}
                pending={pending}
              />
            </div>
          </Row>
        </form>
      </div>
    </>
  );
}

export default EmployeeForm;
