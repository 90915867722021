const AdminData = [
  {
    label: "Dashboard",
    icon: "mdi mdi-home-variant-outline",
    url: "/dashboard",
    issubMenubadge: true,
    // bgcolor: "bg-primary",
    // badgeValue: "3",
  },
  {
    label: "Human Capital",
    icon: "mdi mdi-account-multiple-outline",
    subItem: [
      { sublabel: "Managers", link: "/managers" },
      { sublabel: "Employees", link: "/employees" },
    ],
  },
  {
    label: "Employee Review",
    icon: "mdi mdi-pencil-box-outline",
    url: "/employee/review",
    issubMenubadge: true,
    // bgcolor: "bg-primary",
    // badgeValue: "3",
  },
  {
    label: "Organization Settings",
    icon: "mdi mdi-web",
    url: "/organization-settings",
    issubMenubadge: true,
  },
  {
    label: "Subscriptions",
    icon: "mdi mdi-credit-card-multiple",
    url: "/subscriptions",
    issubMenubadge: true,
  },
  // {
  //   label: "Talent Map",
  //   icon: "mdi mdi-auto-fix",
  //   url: "/#",
  //   issubMenubadge: true,
  // },
];
export default AdminData;
