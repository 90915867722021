import React, { useState } from "react";
import useCustomFetchQuery from "../../../lib/Hooks/useCustomFecthQuery";
import SearchInput from "../../../components/Common/SearchIput";
import MyReviewTable from "../../../components/Pages/Dashboard/Employee/MyReviewTable";
import MonthlyAnalysisis from "../../../components/Pages/Dashboard/Employee/MyMonthlyAnalysis";

const Employee = ({
  userID,
  token,
  organizationID,
  departmentID,
  todayDate,
}) => {
  const [search, setSearch] = useState("");
  const {
    data: employeeAnalytics,
    error,
    isLoading,
  } = useCustomFetchQuery(
    ["EmployeeAnalytics", userID],
    `${process.env.REACT_APP_APIKEY}reports/${userID}/employee`,
    token,
    { enabled: !!userID }
  );

  return (
    <React.Fragment>
      <div className="w-100 mb-4 d-flex justify-content-between">
        <SearchInput
          Title={"Search"}
          search={search}
          setSearch={setSearch}
          radius={20}
        />
        <div style={{ fontSize: 18, fontWeight: "bolder" }}>{todayDate}</div>
      </div>

      <MyReviewTable
        userID={userID}
        token={token}
        organizationID={organizationID}
        departmentID={departmentID}
      />
      <MonthlyAnalysisis
        userID={userID}
        token={token}
        employeeAnalytics={employeeAnalytics}
      />
    </React.Fragment>
  );
};

export default Employee;
