import React from "react";
import { getGreeting } from "../../../../lib/utils/greeting";

const GreetingManager = ({ firstName, todayDate }) => {
  return (
    <div className="w-100 mb-4 d-flex justify-content-between">
      <div className="w-100">
        <div style={{ fontSize: 18, fontWeight: "bolder" }}>
          {getGreeting()}, {firstName} !
        </div>
        <div>Please review the tasks below and take the necessary actions.</div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          fontSize: 18,
          fontWeight: "bolder",
        }}
      >
        {todayDate}
      </div>
    </div>
  );
};

export default GreetingManager;
