import React from "react";
import ReactApexChart from "react-apexcharts";

const ApaexlinecolumnData = {
  series: [
    {
      name: "Net Profit",
      data: [60, 46, 57, 59, 54, 62, 58, 64, 60, 66, 46, 58],
    },
  ],
  options: {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    // "#f46a6a"

    colors: ["#33a6cd"],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: "Number of Reviews",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands";
        },
      },
    },
  },
};

const Apaexlinecolumn = () => {
  return (
    <React.Fragment>
      <ReactApexChart
        options={ApaexlinecolumnData.options}
        series={ApaexlinecolumnData.series}
        type="bar"
        height={350}
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default Apaexlinecolumn;
