import React, { useState, useEffect } from "react";
import usePost from "../../../lib/Hooks/usePost";
import { Spinner, Row, Col } from "reactstrap";

function UpdatePassword({ token }) {
  const { execute, pending, data, status } = usePost();

  const [password, setPassword] = useState(null);
  const [passwordConfirm, setPasswordConfirm] = useState(null);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const Method = "PATCH",
      url = `auth/updateMyPassword`;
    const raw = JSON.stringify({
      password: password,
      passwordConfirm: passwordConfirm,
    });

    execute(url, raw, Method, "Password Successfully Updated", token);
  };

  useEffect(() => {
    if (status === "success") {
      const user = data;
      if (user) {
        localStorage.clear();
        localStorage.setItem("authEventKagoUser", JSON.stringify(user));
      }
    }
  }, [status]);

  return (
    <div className="w-100 d-flex justify-content-center mt-3">
      <form className="form-create-event" onSubmit={handleSubmitForm}>
        <Row>
          <Col md={12}>
            <label>New Password</label>
            <input
              type="text"
              name="password"
              className="mb-3 form-control fromInput w-100"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Col>
          <Col md={12}>
            <label>Confirm New Password </label>
            <input
              type="text"
              name="passwordConfirm"
              className="mb-3 form-control fromInput w-100"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              required
            />
          </Col>
          <div className="d-flex justify-content-between">
            <div>
              <p className="profile-form-title">Password requirements</p>
              <p>Please follow this guide for a strong password</p>
              <ul>
                <li>One special characters</li>
                <li>Min 6 characters</li>
                <li>One number (2 are recommended)</li>
                <li>Change it often</li>
              </ul>
            </div>
            <div className="d-flex justify-content-end align-items-end">
              <div className="">
                <button className="btn btn-primary rounded-5" type="submit">
                  <div className="d-flex justify-content-center align-items-center">
                    {!pending ? <span>Update Password</span> : null}
                    {!pending ? null : (
                      <span>
                        <Spinner as="span" animation="border" size="sm" />{" "}
                        Loading
                      </span>
                    )}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </Row>
      </form>
    </div>
  );
}

export default UpdatePassword;
