import React, { useState, useMemo, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useQueryClient } from "@tanstack/react-query";
import Select from "react-select";
import useCustomMutation from "../../../../lib/Hooks/useCustomMutation";
import usePost from "../../../../lib/Hooks/usePost";
import "./completeReview.css";
import { warningMessage } from "../../../Common/Toast/Toast";
import submitReview from "../../../../lib/utils/submitReview";
import CompleteSummary from "./CompleteSummary";
import GuideLine from "./GuideLine";

const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#33A6CD" : "white",
    color: state.isFocused ? "white" : "black",
  }),
  control: (provided) => ({
    ...provided,
    borderColor: "#ccc",
    "&:hover": {
      borderColor: "#33A6CD",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#aaa",
  }),
};

const SelectQuestion = ({ questionText, value, onChange, options }) => {
  return (
    <div className="question">
      <p>{questionText}</p>
      <Select
        value={options.find((option) => option.value === value) || null}
        onChange={(selected) => onChange(selected ? selected.value : null)}
        options={options}
        styles={customSelectStyles}
        isSearchable={false}
        placeholder="Select Score"
      />
    </div>
  );
};

const SelectSection = ({
  sectionTitle,
  sectionMarks,
  sectionKey,
  questions,
  ranges,
  answers,
  onChange,
}) => {
  return (
    <div className="section">
      <h3>
        {sectionTitle} {sectionMarks}
      </h3>
      {questions.map((question, index) => {
        const options = Array.from({
          length: ranges[index].max - ranges[index].min + 1,
        }).map((_, i) => {
          const value = ranges[index].max - i;
          return { value, label: value.toString() };
        });

        return (
          <SelectQuestion
            key={index}
            questionText={question}
            value={answers[sectionKey][index] || null}
            options={options}
            onChange={(value) => onChange(sectionKey, index, value)}
          />
        );
      })}
    </div>
  );
};

const CompleteReview = ({
  setIsCompleteEvatuation,
  token,
  userID,
  organizationID,
  departmentID,
}) => {
  const queryClient = useQueryClient();
  const { status, execute, pending } = usePost();
  const [answers, setAnswers] = useState({
    qualityOfWork: Array(3).fill(null),
    workHabits: Array(4).fill(null),
    jobKnowledge: Array(3).fill(null),
    interpersonalSkills: Array(5).fill(null),
    leadershipSkills: Array(3).fill(null),
    overallProgress: {
      performanceFailing: null,
      performanceAcceptable: null,
      performanceOutstanding: null,
      comment: "",
      recommendation: "",
    },
  });

  const key = ["MyReviews", organizationID];
  const Mutation = useCustomMutation(key);
  const [currentStep, setCurrentStep] = useState(1);

  const totalScore = useMemo(() => {
    let sum = 0;
    Object.keys(answers).forEach((section) => {
      if (Array.isArray(answers[section])) {
        sum += answers[section].reduce((acc, val) => acc + (val || 0), 0);
      }
    });
    return sum;
  }, [answers]);

  useEffect(() => {
    if (status) {
      queryClient.invalidateQueries(key);
      setAnswers({
        qualityOfWork: Array(3).fill(null),
        workHabits: Array(4).fill(null),
        jobKnowledge: Array(3).fill(null),
        interpersonalSkills: Array(5).fill(null),
        leadershipSkills: Array(3).fill(null),
        overallProgress: {
          performanceFailing: null,
          performanceAcceptable: null,
          performanceOutstanding: null,
          comment: "",
          recommendation: "",
        },
      });
      setIsCompleteEvatuation(false);
    }
  }, [status]);

  const handleSelectChange = (sectionKey, keyOrIndex, value) => {
    setAnswers((prev) => {
      if (Array.isArray(prev[sectionKey])) {
        // Handle array sections like `qualityOfWork`
        const updatedSection = [...prev[sectionKey]];
        updatedSection[keyOrIndex] = value;
        return { ...prev, [sectionKey]: updatedSection };
      } else {
        // Handle object sections like `overallProgress`
        return {
          ...prev,
          [sectionKey]: {
            ...prev[sectionKey],
            [keyOrIndex]: value,
          },
        };
      }
    });
  };

  const validateStepCompletion = () => {
    const currentSectionKey = Object.keys(answers)[currentStep - 1];
    const currentSection = answers[currentSectionKey];
    if (Array.isArray(currentSection)) {
      return currentSection.every((answer) => answer !== null);
    } else if (currentStep === 6) {
      return (
        currentSection.performanceFailing !== null &&
        currentSection.performanceAcceptable !== null &&
        currentSection.performanceOutstanding !== null
      );
    } else if (currentStep === 7) {
      return currentSection.comment.trim() !== "";
    }
    return true;
  };

  const nextStep = () => {
    if (!validateStepCompletion()) {
      warningMessage("Please respond to all the questions before proceeding.");
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const prevStep = () => setCurrentStep((prev) => prev - 1);

  const handleSubmit = (e) => {
    e.preventDefault();
    const outputData = submitReview(answers);
    const url = `assessments?uid=${userID}&oid=${organizationID}&did=${departmentID}`;

    const body = JSON.stringify({ assessment: { employee: outputData } });

    const Method = "POST";

    execute(url, body, Method, "Review successfully submitted", token, null);
  };

  // console.log("output...", JSON.stringify(answers, null, 2));
  return (
    <div>
      <Row>
        <Col md={12} lg={8}>
          <div className="evaluation-form">
            {currentStep === 1 && (
              <SelectSection
                sectionTitle="Quality of Work"
                sectionMarks="(Out of 15 Marks)"
                sectionKey="qualityOfWork"
                questions={[
                  "Accuracy, neatness and timeliness of work",
                  "Adherence to duties and procedures in Job Descriptions and Work Instructions",
                  "Synchronization with organizational/functional goals",
                ]}
                ranges={[
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                ]}
                answers={answers}
                onChange={handleSelectChange}
              />
            )}

            {currentStep === 2 && (
              <SelectSection
                sectionTitle="Work Habits"
                sectionMarks="(Out of 20 Marks)"
                sectionKey="workHabits"
                questions={[
                  "Punctuality to workplace",
                  "Attendance",
                  "Does the employee stay busy, look for things to do, initiates at workplace",
                  "Submits reports on time and meets deadlines",
                ]}
                ranges={[
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                ]}
                answers={answers}
                onChange={handleSelectChange}
              />
            )}

            {currentStep === 3 && (
              <SelectSection
                sectionTitle="Job Knowledge"
                sectionMarks="(Out of 15 Marks)"
                sectionKey="jobKnowledge"
                questions={[
                  "Skill and ability to perform job satisfactorily",
                  "Shown interest in learning and improving",
                  "Problem-solving ability",
                ]}
                ranges={[
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                ]}
                answers={answers}
                onChange={handleSelectChange}
              />
            )}

            {currentStep === 4 && (
              <SelectSection
                sectionTitle="Interpersonal Skills"
                sectionMarks="(Out of 25 Marks)"
                sectionKey="interpersonalSkills"
                questions={[
                  "Responds and contributes to team efforts",
                  "Responds positively to suggestions and instructions",
                  "Keeps supervisor informed of all details",
                  "Adapts well to changing circumstances",
                  "Seeks feedback to improve",
                ]}
                ranges={[
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                ]}
                answers={answers}
                onChange={handleSelectChange}
              />
            )}

            {currentStep === 5 && (
              <SelectSection
                sectionTitle="Leadership Skills"
                sectionMarks="(Out of 25 Marks)"
                sectionKey="leadershipSkills"
                questions={[
                  "Aspirant to climb up the ladder, accepts challenges, new responsibilities and roles",
                  "Innovative thinking - contribution to organizations and functions and personal growth",
                  "Work motivation",
                ]}
                ranges={[
                  { min: 1, max: 10 },
                  { min: 1, max: 10 },
                  { min: 1, max: 5 },
                ]}
                answers={answers}
                onChange={handleSelectChange}
              />
            )}

            {currentStep === 6 && (
              <div className="section">
                <h3>Overall Progress</h3>
                <div className="question">
                  <p>
                    Your performance and learning is unsatisfactory and is
                    failing to improve at a satisfactory rate
                  </p>
                  <div className="choices gap-3">
                    <button
                      className={`choice-button ${
                        answers.overallProgress.performanceFailing === "Yes"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleSelectChange(
                          "overallProgress",
                          "performanceFailing",
                          "Yes"
                        )
                      }
                    >
                      Yes
                    </button>
                    <button
                      className={`choice-button ${
                        answers.overallProgress.performanceFailing === "No"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleSelectChange(
                          "overallProgress",
                          "performanceFailing",
                          "No"
                        )
                      }
                    >
                      No
                    </button>
                  </div>
                </div>
                <div className="question">
                  <p>
                    Your performance and learning is acceptable and is improving
                    at a satisfactory rate
                  </p>
                  <div className="choices gap-3">
                    <button
                      className={`choice-button ${
                        answers.overallProgress.performanceAcceptable === "Yes"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleSelectChange(
                          "overallProgress",
                          "performanceAcceptable",
                          "Yes"
                        )
                      }
                    >
                      Yes
                    </button>
                    <button
                      className={`choice-button ${
                        answers.overallProgress.performanceAcceptable === "No"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleSelectChange(
                          "overallProgress",
                          "performanceAcceptable",
                          "No"
                        )
                      }
                    >
                      No
                    </button>
                  </div>
                </div>
                <div className="question">
                  <p>
                    You have successfully demonstrated outstanding overall
                    performance
                  </p>
                  <div className="choices gap-3">
                    <button
                      className={`choice-button ${
                        answers.overallProgress.performanceOutstanding === "Yes"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleSelectChange(
                          "overallProgress",
                          "performanceOutstanding",
                          "Yes"
                        )
                      }
                    >
                      Yes
                    </button>
                    <button
                      className={`choice-button ${
                        answers.overallProgress.performanceOutstanding === "No"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleSelectChange(
                          "overallProgress",
                          "performanceOutstanding",
                          "No"
                        )
                      }
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            )}

            {currentStep === 7 && (
              <div className="section">
                <h3>
                  Comments <span className="text-danger">*</span>
                </h3>
                <div className="question">
                  <div>
                    <label>Write your comment below</label>
                  </div>
                  <textarea
                    value={answers.overallProgress.comment}
                    onChange={(e) =>
                      handleSelectChange(
                        "overallProgress",
                        "comment",
                        e.target.value
                      )
                    }
                    className="w-100 p-2 rounded-3"
                    rows="4"
                  />
                </div>
              </div>
            )}

            <div className="form-navigation">
              {currentStep > 1 && (
                <button className="back-button" onClick={prevStep}>
                  Back
                </button>
              )}
              {currentStep < 7 && (
                <button className="next-button" onClick={nextStep}>
                  Next
                </button>
              )}
              {currentStep === 7 && (
                <button
                  className="submit-button"
                  onClick={handleSubmit}
                  disabled={pending}
                >
                  {pending ? "Submitting..." : "Submit"}
                </button>
              )}
            </div>
          </div>
        </Col>
        <Col md={12} lg={4}>
          <CompleteSummary answers={answers} totalScore={totalScore} />
          <GuideLine />
        </Col>
      </Row>
    </div>
  );
};

export default CompleteReview;
