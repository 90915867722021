import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
} from "reactstrap";
import { useQueryClient } from "@tanstack/react-query";
import useCustomFetchQuery from "../../../lib/Hooks/useCustomFecthQuery";
import useCustomMutation from "../../../lib/Hooks/useCustomMutation";
import usePost from "../../../lib/Hooks/usePost";
import { BsPlusCircleDotted } from "react-icons/bs";
import { TbEdit } from "react-icons/tb";
import { MdDelete } from "react-icons/md";
import ButtonSubmit from "../../Common/Buttons/ButtonSubmit";

const gradientColors = [
  "linear-gradient(135deg, #ff9a9e 0%, #fad0c4 100%)",
  "linear-gradient(135deg, #a18cd1 0%, #fbc2eb 100%)",
  "linear-gradient(135deg, #f093fb 0%, #f5576c 100%)",
  "linear-gradient(135deg, #4facfe 0%, #00f2fe 100%)",
  "linear-gradient(135deg, #43e97b 0%, #38f9d7 100%)",
  "linear-gradient(135deg, #fa709a 0%, #fee140 100%)",
  "linear-gradient(135deg, #30cfd0 0%, #330867 100%)",
  "linear-gradient(135deg, #a1c4fd 0%, #c2e9fb 100%)",
];

const Department = ({ organizationID, token }) => {
  const queryClient = useQueryClient();
  const { status, execute, pending } = usePost();
  const [viewDepartments, setViewDepartments] = useState([]);
  const [inputs, setInputs] = useState({});
  const [inputsEdit, setInputsEdit] = useState({
    name: "",
    departmentID: null,
  });
  const [departmentName, setDepartmentName] = useState(null);
  const [inputsDelete, setInputsDelete] = useState({
    name: "",
    departmentID: null,
  });
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const key = ["AllDepartments", organizationID];
  const addDepMutation = useCustomMutation(key);
  const editDepMutation = useCustomMutation(key);
  const deleteDepMutation = useCustomMutation(key);
  const {
    data: departmentDetails,
    error,
    isLoading,
  } = useCustomFetchQuery(
    ["AllDepartments", organizationID],
    `${process.env.REACT_APP_APIKEY}departments/${organizationID}/organization`,
    token,
    { enabled: !!organizationID }
  );

  const toggleAddDepartment = () => {
    setIsAddModal(!isAddModal);
  };

  const toggleEditModal = (name, id) => {
    setInputsEdit({ name: name, departmentID: id });
    setIsEditModal(!isEditModal);
  };

  const toggleDeleteModal = (name, id) => {
    setDepartmentName(name);
    setInputsDelete({ name: "", departmentID: id });
    setIsDeleteModal(!isDeleteModal);
  };

  const addDepartment = (e) => {
    e.preventDefault();
    const Method = "POST",
      url = `departments/${organizationID}`;
    const raw = JSON.stringify({
      name: inputs?.departmentName,
    });
    // const data = {
    //   endPoint: url,
    //   bodyContent: raw,
    //   Method: Method,
    //   displayMessage: "New department successfully added",
    //   token: token,
    // };
    execute(url, raw, Method, "New department successfully added", token, null);
    // execute;
    // addDepMutation.mutate(data);
  };
  const editDepartment = (e) => {
    e.preventDefault();
    const Method = "PATCH",
      url = `departments/${inputsEdit?.departmentID}`;
    const raw = JSON.stringify({
      name: inputsEdit?.name ? inputsEdit?.name : null,
    });
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "New department successfully added",
      token: token,
    };
    editDepMutation.mutate(data);
  };
  const deleteDepartment = (e) => {
    e.preventDefault();
    const Method = "DELETE",
      url = `departments/${inputsDelete?.departmentID}`;
    const raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "department successfully deleted",
      token: token,
    };
    deleteDepMutation.mutate(data);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setInputsEdit((inputsEdit) => ({ ...inputsEdit, [name]: value }));
  };
  const handleDeleteChange = (event) => {
    const { name, value } = event.target;
    setInputsDelete((inputsDelete) => ({ ...inputsDelete, [name]: value }));
  };

  useEffect(() => {
    if (departmentDetails?.data) {
      setViewDepartments(departmentDetails?.data);
    }
    // if (addDepMutation?.isSuccess) {
    //   setInputs({ name: "" });
    //   setIsAddModal(false);
    // }
    if (editDepMutation?.isSuccess) {
      setInputsEdit({ name: "" });
      setIsEditModal(false);
    }
    if (deleteDepMutation?.isSuccess) {
      setInputsDelete({ name: "" });
      setIsDeleteModal(false);
    }
    if (status) {
      queryClient.invalidateQueries(key);
      setInputs({ name: "" });
      setIsAddModal(false);
      // Navigate(`/checkout?data=${data?.data}`);
    }
  }, [
    departmentDetails?.data,
    addDepMutation?.isSuccess,
    editDepMutation?.isSuccess,
    deleteDepMutation?.isSuccess,
    status,
  ]);

  return (
    <>
      <Row>
        <Col md={12}>
          <div className="w-100 mb-2 d-flex justify-content-center">
            <h4>Department</h4>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col
          md={12}
          className="mt-3 d-flex gap-3 flex-wrap"
          style={{ width: "90%" }}
        >
          <Card
            style={{
              backgroundColor: "white",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
              transition: "transform 0.3s ease",
            }}
            className="zoom-card"
            onClick={toggleAddDepartment}
          >
            <CardBody
              style={{
                width: 240,
                height: 90,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
              }}
            >
              <BsPlusCircleDotted size={40} />
            </CardBody>
          </Card>

          {viewDepartments?.map((department, index) => (
            <Card
              key={index}
              style={{
                background: gradientColors[index % gradientColors.length],
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                transition: "transform 0.3s ease",
                position: "relative",
              }}
              className="zoom-card"
            >
              <CardBody
                style={{
                  width: 240,
                  height: 90,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 10,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: "bolder",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  {department.name}
                </div>

                <div className="action-buttons d-flex gap-1">
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 5,
                      backgroundColor: "#1C591B",
                    }}
                    onClick={() =>
                      toggleEditModal(department?.name, department?._id)
                    }
                  >
                    <TbEdit size={15} color="#fff" />
                  </div>

                  <div
                    style={{
                      width: 20,
                      height: 20,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 5,
                      backgroundColor: "#C70000",
                    }}
                    onClick={() =>
                      toggleDeleteModal(department?.name, department?._id)
                    }
                  >
                    <MdDelete size={15} color="#fff" />
                  </div>
                </div>
              </CardBody>
            </Card>
          ))}
        </Col>
      </Row>

      <Modal
        isOpen={isAddModal}
        toggle={() => setIsAddModal(!isAddModal)}
        size="md"
      >
        <ModalHeader toggle={() => setIsAddModal(!isAddModal)}>
          New Department
        </ModalHeader>
        <ModalBody>
          <div className="w-100">
            <form
              noValidate
              autoComplete="off"
              data-testid="form"
              onSubmit={addDepartment}
            >
              <div className="w-100">
                <label>
                  Department Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="departmentName"
                  value={inputs?.departmentName}
                  // placeholder="Enter Department name"
                  onChange={handleChange}
                  style={{ borderRadius: 7 }}
                  className="mb-3 form-control fromInput border-dark"
                  required
                />
              </div>
              <div className="w-100 d-flex justify-content-center">
                <ButtonSubmit
                  Title="Add Department"
                  ColorText="white"
                  BorderColor="#33A6CD"
                  BackgroundColor="#33A6CD"
                  borderRadius="25px"
                  handleOnclick={addDepartment}
                  pending={pending}
                />
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={isEditModal} toggle={() => setIsEditModal(!isEditModal)}>
        <ModalHeader toggle={() => setIsEditModal(!isEditModal)}>
          Edit Department
        </ModalHeader>
        <ModalBody>
          <div className="w-100">
            <form
              noValidate
              autoComplete="off"
              data-testid="form"
              onSubmit={editDepartment}
            >
              <div className="w-100">
                <label>
                  Department Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={inputsEdit?.name}
                  onChange={handleEditChange}
                  style={{ borderRadius: 7 }}
                  className="mb-3 form-control fromInput border-dark"
                  required
                />
              </div>
              <div className="w-100 d-flex justify-content-center">
                <ButtonSubmit
                  Title="Update"
                  ColorText="white"
                  BorderColor="#33A6CD"
                  BackgroundColor="#33A6CD"
                  borderRadius="25px"
                  handleOnclick={editDepartment}
                  pending={editDepMutation.isLoading}
                />
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={isDeleteModal}
        toggle={() => setIsDeleteModal(!isDeleteModal)}
      >
        <ModalHeader toggle={() => setIsDeleteModal(!isDeleteModal)}>
          Delete Department
        </ModalHeader>
        <ModalBody>
          <p className="">
            To Delete department enter{" "}
            <span style={{ fontWeight: "700" }}>{departmentName}</span>{" "}
          </p>
          <form
            noValidate
            autoComplete="off"
            data-testid="form"
            onSubmit={deleteDepartment}
          >
            <div className="w-100">
              <label>
                Department Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="name"
                value={inputsDelete?.name}
                onChange={handleDeleteChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput border-dark"
                required
              />
            </div>
            <div className="w-100 d-flex justify-content-between">
              <ButtonSubmit
                Title="Cancel"
                ColorText="white"
                BorderColor="#74788d"
                BackgroundColor="#74788d"
                borderRadius="25px"
                handleOnclick={toggleDeleteModal}
                pending={false}
                // pending={mutation?.isPending}
              />
              {departmentName !== inputsDelete?.name ? (
                <ButtonSubmit
                  Title="Delete"
                  ColorText="white"
                  BorderColor="#74788d"
                  BackgroundColor="#74788d"
                  borderRadius="25px"
                  handleOnclick={() => {}}
                  pending={false}
                />
              ) : null}

              {departmentName === inputsDelete?.name ? (
                <ButtonSubmit
                  Title="Delete"
                  ColorText="white"
                  BorderColor="#ff0000"
                  BackgroundColor="#ff0000"
                  borderRadius="25px"
                  handleOnclick={deleteDepartment}
                  pending={deleteDepMutation?.isLoading}
                />
              ) : null}
            </div>
          </form>
        </ModalBody>
      </Modal>

      <style>{`
        .zoom-card:hover {
          transform: scale(1.05);
        }

        /* Hide action buttons initially */
        .action-buttons {
          position: absolute;
          top: 5px;
          right: 3px;
          opacity: 0;
          transition: opacity 0.3s ease;
        }

        /* Show action buttons on hover */
        .zoom-card:hover .action-buttons {
          opacity: 1;
        }
      `}</style>
    </>
  );
};

export default Department;
