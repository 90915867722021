export const Container = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 15,
  userSelect: "none",
};

export const Wrapper = {
  width: "100%",
  borderWidth: 2,
  borderColor: "#D9D9D9",
  borderRadius: 15,
  borderStyle: "solid",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  //   backgroundColor: "purple",
};

export const Title = {
  display: "flex",
  justifyContent: "center",
  fontSize: 14,
  fontWeight: "bolder",
};

export const SubTitle = {
  display: "flex",
  justifyContent: "center",
  fontSize: 29,
  fontWeight: "bolder",
};

export const employeeTblTitle = {
  width: "100%",
  display: "flex",
  padding: 5,
  justifyContent: "center",
  alignItems: "center",
  fontSize: 16,
  fontWeight: "bolder",
};

export const employeeTbl = {
  borderRadius: 10,
  padding: 5,
  borderWidth: 2,
  borderStyle: "solid",
  borderColor: "#D9D9D9",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
};

export const managerCard = {
  width: 280,
  height: 120,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 10,
  padding: 5,
  borderWidth: 0.5,
  borderStyle: "solid",
  borderColor: "#D9D9D9",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
};

export const managerCardWrapper = {
  width: "100%",
};

export const managerCardTitle = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  fontSize: 18,
  fontWeight: "bolder",
  color: "#000",
  marginBottom: 7,
};

export const managerCardTasks = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  fontSize: 20,
  fontWeight: "bolder",
};

export const lastest = {
  container: {
    background: "white",
    // borderRadius: "8px",
    // boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    width: "100%",
    maxWidth: "800px",
  },
  tabsContainer: {
    display: "flex",
    borderBottom: "1px solid #e5e7eb",
  },
  tab: {
    padding: "16px 24px",
    fontSize: "14px",
    fontWeight: 500,
    background: "none",
    border: "none",
    // borderBottom: "2px solid transparent",
    color: "#6b7280",
    cursor: "pointer",
    transition: "all 0.2s",
  },
  inactiveTab: {
    border: "none",
    borderBottom: "2px solid red",
    borderBottomColor: "transparent",
  },
  activeTab: {
    color: "#33a6cd",
    borderBottom: "2px solid #33a6cd",
    borderBottomColor: "#33a6cd",
  },
  tableContainer: {
    padding: "24px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  th: {
    textAlign: "left",
    padding: "12px 16px",
    fontSize: "14px",
    fontWeight: 500,
    color: "#6b7280",
  },
  td: {
    padding: "12px 16px",
    fontSize: "14px",
    borderBottom: "1px solid #e5e7eb",
  },
  clientName: {
    color: "#33a6cd",
    fontWeight: 500,
  },
  amount: {
    textAlign: "right",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    gap: "4px",
    padding: "24px",
  },
  pContainer: {
    position: "absolute",
    width: "100%",
    bottom: 0,
  },
  pageButton: {
    width: 40,
    height: 40,
    padding: "12px",
    fontSize: "14px",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // border: "1px solid #e5e7eb",
    background: "white",
    borderRadius: "50%",
    cursor: "pointer",
    transition: "all 0.2s",
  },
  activePageButton: {
    // border: "1px solid #e5e7eb",
    // border: "1px solid #33a6cd",
    background: "#33a6cd",
    color: "white",
    borderColor: "#33a6cd",
    borderRadius: "50%",
  },
  disabledButton: {
    opacity: 0.5,
    cursor: "not-allowed",
  },
};
