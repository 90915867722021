export const convertReviewsDate = (dateString = new Date()) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export const dummyDataDue = [
  {
    _id: "672a27836406f7c1f1b7a60bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "I tried very hard",
        total: 71,
        _id: "672a27836406f7c1f1b7a60cc",
        id: "672a27836406f7c1f1b7a60cc",
      },
      _id: "672a27836406f7c1f1b7a60ccc",
    },
    date: convertReviewsDate("2024-11-05T14:09:53.244Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "01",
      firstName: "John",
      lastName: "Doe",
      status: true,
      id: "001",
    },
    status: "Due",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a60bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a61bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Did a great job",
        total: 88,
        _id: "672a27836406f7c1f1b7a61cc",
        id: "672a27836406f7c1f1b7a61cc",
      },
      _id: "672a27836406f7c1f1b7a61ccc",
    },
    date: convertReviewsDate("2024-10-21T10:45:30.500Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "02",
      firstName: "Alice",
      lastName: "Smith",
      status: true,
      id: "002",
    },
    status: "Due",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a61bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a62bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Improvement needed",
        total: 55,
        _id: "672a27836406f7c1f1b7a62cc",
        id: "672a27836406f7c1f1b7a62cc",
      },
      _id: "672a27836406f7c1f1b7a62ccc",
    },
    date: convertReviewsDate("2024-09-15T08:30:45.100Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "03",
      firstName: "Michael",
      lastName: "Johnson",
      status: true,
      id: "003",
    },
    status: "Due",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a62bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a63bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Excellent performance",
        total: 95,
        _id: "672a27836406f7c1f1b7a63cc",
        id: "672a27836406f7c1f1b7a63cc",
      },
      _id: "672a27836406f7c1f1b7a63ccc",
    },
    date: convertReviewsDate("2024-08-05T13:20:00.300Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "04",
      firstName: "Emily",
      lastName: "Davis",
      status: true,
      id: "004",
    },
    status: "Due",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a63bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a64bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Needs more training",
        total: 62,
        _id: "672a27836406f7c1f1b7a64cc",
        id: "672a27836406f7c1f1b7a64cc",
      },
      _id: "672a27836406f7c1f1b7a64ccc",
    },
    date: convertReviewsDate("2024-07-20T15:10:20.800Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "05",
      firstName: "Sophia",
      lastName: "Brown",
      status: true,
      id: "005",
    },
    status: "Due",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a64bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a65bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Great team player",
        total: 78,
        _id: "672a27836406f7c1f1b7a65cc",
        id: "672a27836406f7c1f1b7a65cc",
      },
      _id: "672a27836406f7c1f1b7a65ccc",
    },
    date: convertReviewsDate("2024-06-18T12:40:10.600Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "06",
      firstName: "Oliver",
      lastName: "Miller",
      status: true,
      id: "006",
    },
    status: "Due",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a65bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a66bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Exceeded expectations",
        total: 92,
        _id: "672a27836406f7c1f1b7a66cc",
        id: "672a27836406f7c1f1b7a66cc",
      },
      _id: "672a27836406f7c1f1b7a66ccc",
    },
    date: convertReviewsDate("2024-05-10T09:15:50.200Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "07",
      firstName: "James",
      lastName: "Wilson",
      status: true,
      id: "007",
    },
    status: "Due",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a66bcc",
  },
];

export const dummyDataOutstanding = [
  {
    _id: "672a27836406f7c1f1b7a60bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "I tried very hard",
        total: 71,
        _id: "672a27836406f7c1f1b7a60cc",
        id: "672a27836406f7c1f1b7a60cc",
      },
      _id: "672a27836406f7c1f1b7a60ccc",
    },
    date: convertReviewsDate("2024-11-05T14:09:53.244Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "01",
      firstName: "John",
      lastName: "Doe",
      status: true,
      id: "001",
    },
    status: "Outstanding",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a60bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a61bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Great attention to detail",
        total: 90,
        _id: "672a27836406f7c1f1b7a61cc",
        id: "672a27836406f7c1f1b7a61cc",
      },
      _id: "672a27836406f7c1f1b7a61ccc",
    },
    date: convertReviewsDate("2024-10-01T10:20:30.500Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "02",
      firstName: "Alice",
      lastName: "Cooper",
      status: true,
      id: "002",
    },
    status: "Outstanding",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a61bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a62bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Delivered exceptional results",
        total: 95,
        _id: "672a27836406f7c1f1b7a62cc",
        id: "672a27836406f7c1f1b7a62cc",
      },
      _id: "672a27836406f7c1f1b7a62ccc",
    },
    date: convertReviewsDate("2024-09-15T08:30:45.100Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "03",
      firstName: "Michael",
      lastName: "Taylor",
      status: true,
      id: "003",
    },
    status: "Outstanding",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a62bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a63bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Set a new standard",
        total: 99,
        _id: "672a27836406f7c1f1b7a63cc",
        id: "672a27836406f7c1f1b7a63cc",
      },
      _id: "672a27836406f7c1f1b7a63ccc",
    },
    date: convertReviewsDate("2024-08-18T14:20:00.300Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "04",
      firstName: "Emily",
      lastName: "Clark",
      status: true,
      id: "004",
    },
    status: "Outstanding",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a63bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a64bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Exemplary leadership",
        total: 93,
        _id: "672a27836406f7c1f1b7a64cc",
        id: "672a27836406f7c1f1b7a64cc",
      },
      _id: "672a27836406f7c1f1b7a64ccc",
    },
    date: convertReviewsDate("2024-07-25T11:30:20.800Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "05",
      firstName: "Sophia",
      lastName: "Martinez",
      status: true,
      id: "005",
    },
    status: "Outstanding",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a64bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a65bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Innovative problem solver",
        total: 88,
        _id: "672a27836406f7c1f1b7a65cc",
        id: "672a27836406f7c1f1b7a65cc",
      },
      _id: "672a27836406f7c1f1b7a65ccc",
    },
    date: convertReviewsDate("2024-06-18T12:40:10.600Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "06",
      firstName: "Oliver",
      lastName: "Garcia",
      status: true,
      id: "006",
    },
    status: "Outstanding",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a65bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a66bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Pioneered new techniques",
        total: 97,
        _id: "672a27836406f7c1f1b7a66cc",
        id: "672a27836406f7c1f1b7a66cc",
      },
      _id: "672a27836406f7c1f1b7a66ccc",
    },
    date: convertReviewsDate("2024-05-12T09:15:50.200Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "07",
      firstName: "James",
      lastName: "Hernandez",
      status: true,
      id: "007",
    },
    status: "Outstanding",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a66bcc",
  },
];

export const dummyDataClose = [
  {
    _id: "672a27836406f7c1f1b7a60bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "I tried very hard",
        total: 71,
        _id: "672a27836406f7c1f1b7a60cc",
        id: "672a27836406f7c1f1b7a60cc",
      },
      _id: "672a27836406f7c1f1b7a60ccc",
    },
    date: convertReviewsDate("2024-11-05T14:09:53.244Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "01",
      firstName: "John",
      lastName: "Doe",
      status: true,
      id: "001",
    },
    status: "Close",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a60bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a70bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Consistently performs well",
        total: 82,
        _id: "672a27836406f7c1f1b7a70cc",
        id: "672a27836406f7c1f1b7a70cc",
      },
      _id: "672a27836406f7c1f1b7a70ccc",
    },
    date: convertReviewsDate("2024-10-20T09:15:00.600Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "02",
      firstName: "Emma",
      lastName: "Brown",
      status: true,
      id: "002",
    },
    status: "Close",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a70bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a71bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Delivers projects on time",
        total: 78,
        _id: "672a27836406f7c1f1b7a71cc",
        id: "672a27836406f7c1f1b7a71cc",
      },
      _id: "672a27836406f7c1f1b7a71ccc",
    },
    date: convertReviewsDate("2024-09-30T08:45:30.400Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "03",
      firstName: "Liam",
      lastName: "Smith",
      status: true,
      id: "003",
    },
    status: "Close",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a71bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a72bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "A reliable team player",
        total: 85,
        _id: "672a27836406f7c1f1b7a72cc",
        id: "672a27836406f7c1f1b7a72cc",
      },
      _id: "672a27836406f7c1f1b7a72ccc",
    },
    date: convertReviewsDate("2024-08-25T12:10:20.300Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "04",
      firstName: "Olivia",
      lastName: "Johnson",
      status: true,
      id: "004",
    },
    status: "Close",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a72bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a73bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Leads by example",
        total: 89,
        _id: "672a27836406f7c1f1b7a73cc",
        id: "672a27836406f7c1f1b7a73cc",
      },
      _id: "672a27836406f7c1f1b7a73ccc",
    },
    date: convertReviewsDate("2024-07-15T15:30:10.700Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "05",
      firstName: "Noah",
      lastName: "Davis",
      status: true,
      id: "005",
    },
    status: "Close",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a73bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a74bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Impressive initiative",
        total: 92,
        _id: "672a27836406f7c1f1b7a74cc",
        id: "672a27836406f7c1f1b7a74cc",
      },
      _id: "672a27836406f7c1f1b7a74ccc",
    },
    date: convertReviewsDate("2024-06-10T14:00:50.200Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "06",
      firstName: "Sophia",
      lastName: "Martinez",
      status: true,
      id: "006",
    },
    status: "Close",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a74bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a75bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Adept at problem solving",
        total: 87,
        _id: "672a27836406f7c1f1b7a75cc",
        id: "672a27836406f7c1f1b7a75cc",
      },
      _id: "672a27836406f7c1f1b7a75ccc",
    },
    date: convertReviewsDate("2024-05-22T13:20:00.400Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "07",
      firstName: "James",
      lastName: "Lopez",
      status: true,
      id: "007",
    },
    status: "Close",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a75bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a76bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Achieved major milestones",
        total: 94,
        _id: "672a27836406f7c1f1b7a76cc",
        id: "672a27836406f7c1f1b7a76cc",
      },
      _id: "672a27836406f7c1f1b7a76ccc",
    },
    date: convertReviewsDate("2024-04-15T16:45:20.300Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "08",
      firstName: "Isabella",
      lastName: "Wilson",
      status: true,
      id: "008",
    },
    status: "Close",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a76bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a77bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Focused and detail-oriented",
        total: 79,
        _id: "672a27836406f7c1f1b7a77cc",
        id: "672a27836406f7c1f1b7a77cc",
      },
      _id: "672a27836406f7c1f1b7a77ccc",
    },
    date: convertReviewsDate("2024-03-20T11:50:30.700Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "09",
      firstName: "Charlotte",
      lastName: "Harris",
      status: true,
      id: "009",
    },
    status: "Close",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a77bcc",
  },
  {
    _id: "672a27836406f7c1f1b7a78bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "Pioneered new strategies",
        total: 96,
        _id: "672a27836406f7c1f1b7a78cc",
        id: "672a27836406f7c1f1b7a78cc",
      },
      _id: "672a27836406f7c1f1b7a78ccc",
    },
    date: convertReviewsDate("2024-02-25T10:25:40.900Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: "10",
      firstName: "Elijah",
      lastName: "Young",
      status: true,
      id: "010",
    },
    status: "Close",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a78bcc",
  },
];
