import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactUsSchema } from "../../../lib/utils/dataValidation";
import { Alert, Row, Col } from "reactstrap";
import usePost from "../../../lib/Hooks/usePost";
import PhoneInput from "react-phone-number-input";
import ButtonSubmit from "../Buttons/ButtonSubmit";
import "react-phone-number-input/style.css";

const ContactUs = ({ setIstoggleContact }) => {
  const [inputs, setInputs] = useState({ phoneNumber: 0 });
  const { status, execute, pending } = usePost();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(contactUsSchema) });

  const onSubmit = async (data) => {
    const Method = "POST",
      url = `auth/lead`;
    const raw = JSON.stringify({ ...data, phoneNumber: inputs.phoneNumber });
    await execute(url, raw, Method, "Successfully sent", null, null);
  };

  useEffect(() => {
    if (status === "success") {
      setIstoggleContact(false);
    }
  }, [status]);

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        {Object.keys(errors).length > 0 && (
          <div className="w-100 d-flex justify-content-center mb-1">
            <Alert color="danger">
              Please verify that all information has been entered correctly.
            </Alert>
          </div>
        )}
        <Row>
          <Col md={12}>
            <label>
              First name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="firstName"
              value={inputs.firstName}
              placeholder="Enter first name"
              style={{ borderRadius: 7 }}
              className={`mb-3 form-control fromInput ${
                errors.firstName ? "border border-danger" : ""
              }`}
              {...register("firstName")}
            />
            {errors.firstName && (
              <p className="text-danger">{errors.firstName.message}</p>
            )}
          </Col>
          <Col md={12}>
            <label>
              Last name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="lastName"
              value={inputs.lastName}
              placeholder="Enter last name"
              style={{ borderRadius: 7 }}
              className={`mb-3 form-control fromInput ${
                errors.lastName ? "border border-danger" : ""
              }`}
              {...register("lastName")}
            />
            {errors.lastName && (
              <p className="text-danger">{errors.lastName.message}</p>
            )}
          </Col>
          <Col md={12}>
            <label>
              Email <span className="text-danger">*</span>
            </label>
            <input
              type="email"
              name="email"
              value={inputs.email}
              placeholder="Enter email"
              style={{ borderRadius: 7 }}
              className={`mb-3 form-control fromInput ${
                errors.email ? "border border-danger" : ""
              } `}
              {...register("email")}
            />
            {errors.email && (
              <p className="text-danger">{errors.email.message}</p>
            )}
          </Col>
          <Col md={12}>
            <label>
              Phone Number <span className="text-danger">*</span>
            </label>
            <div
              className={`phone-input-container ${
                inputs.phoneNumber.length < 12 && inputs.phoneNumber !== null
                  ? "border border-danger"
                  : ""
              }`}
            >
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="ZA"
                value={inputs.phoneNumber ? inputs.phoneNumber : null}
                onChange={(value) =>
                  setInputs({ ...inputs, phoneNumber: value ? value : 0 })
                }
                className="custom-phone-input"
              />
            </div>
            {inputs.phoneNumber.length < 12 && inputs.phoneNumber !== null ? (
              <p className="text-danger"> Required valid phone number </p>
            ) : null}
          </Col>
          <Col md={12} className="mt-2">
            <label>
              How many employees <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              name="numberOfEmployees"
              value={inputs.numberOfEmployees}
              style={{ borderRadius: 7 }}
              className={`mb-3 form-control fromInput ${
                errors.numberOfEmployees ? "border border-danger" : ""
              } `}
              {...register("numberOfEmployees")}
            />
            {errors.numberOfEmployees && (
              <p className="text-danger">{errors.numberOfEmployees.message}</p>
            )}
          </Col>

          <div className="w-100 mt-3 d-flex justify-content-center">
            <ButtonSubmit
              Title="Send"
              ColorText="white"
              BorderColor="#33A6CD"
              BackgroundColor="#33A6CD"
              borderRadius="25px"
              pending={pending}
            />
          </div>
        </Row>
      </form>
    </>
  );
};

export default ContactUs;
