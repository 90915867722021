import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert } from "reactstrap";
import usePost from "../../../lib/Hooks/usePost";
import { registerOwnerSchema } from "../../../lib/utils/dataValidation";
import { ButtonBtn, ButtonSubmit } from "../../Common/Buttons/index";
import UserForm from "../../Authentication/CreateAccount/UserForm";
import UserPasswordForm from "../../Authentication/CreateAccount/UserPasswordForm";

import "./ownerRegistration.scss";

const CreateAccount = () => {
  const subscription = JSON.parse(localStorage.getItem("subscriptionHC"));
  const Navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  const [inputs, setInputs] = useState({
    phoneNumber: 0,
  });
  const [next, setNext] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { status, execute, pending } = usePost();

  // Navigation between steps
  const handleNextStep = () => {
    currentStep < 2 && setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () =>
    currentStep > 1 && setCurrentStep(currentStep - 1);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(registerOwnerSchema) });

  const onSubmit = async (data) => {
    const url = `auth/admin?pid=${subscription?.pID}`;
    const raw = JSON.stringify({ ...data, phoneNumber: inputs.phoneNumber });
    await execute(url, raw, "POST", true);
  };

  useEffect(() => {
    if (status === "success") {
      Navigate(`/message`);
    }
  }, [status]);

  return (
    <div className="organizer-general-container">
      <div className="multi-step-container">
        <div className="multi-step-sidebar" style={{ userSelect: "none" }}>
          {[1, 2].map((step) => (
            <div
              key={step}
              className={`multi-step-step ${
                currentStep === step ? "multi-step-active" : ""
              }`}
              onClick={() => setCurrentStep(step)}
            >
              <div className="multi-step-circle">{step}</div>
              <div className="multi-step-info">
                <h4>STEP {step}</h4>
                <p>
                  {step === 1 ? "Personal Info" : step === 2 ? "Password" : ""}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Form content */}

        <div className="multi-step-form-container">
          {Object.keys(errors).length > 0 && (
            <div className="w-100 d-flex justify-content-center">
              <Alert color="danger">
                Please verify that all information has been entered correctly.
              </Alert>
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            {currentStep === 1 && (
              <>
                <h2>Personal Info</h2>
                <p>Please provide your personal information.</p>
                <UserForm
                  inputs={inputs}
                  setInputs={setInputs}
                  setNext={setNext}
                  watch={watch}
                  register={register}
                  errors={errors}
                />
              </>
            )}

            {currentStep === 2 && (
              <>
                <h2>Password</h2>
                <p>Please provide your password.</p>
                <UserPasswordForm
                  inputs={inputs}
                  setInputs={setInputs}
                  isChecked={isChecked}
                  setIsChecked={setIsChecked}
                  register={register}
                  errors={errors}
                />
              </>
            )}

            {/* Navigation buttons */}
            <div className="multi-step-navigation">
              {currentStep > 1 && (
                <ButtonBtn
                  Title="Previous"
                  type="button"
                  BackgroundColor="#1d3f72"
                  ColorText="#fff"
                  BorderColor="#1d3f72"
                  borderRadius={20}
                  handleOnclick={handlePreviousStep}
                  pending={false}
                  width={100}
                  height={40}
                />
              )}

              {currentStep < 2 && next ? (
                <ButtonBtn
                  Title="Next"
                  type="button"
                  BackgroundColor="#1d3f72"
                  ColorText="#fff"
                  BorderColor="#1d3f72"
                  borderRadius={20}
                  handleOnclick={handleNextStep}
                  pending={false}
                />
              ) : null}

              {currentStep > 1 && currentStep < 2 && next ? (
                <ButtonBtn
                  Title="Next"
                  type="button"
                  BackgroundColor="#1d3f72"
                  ColorText="#fff"
                  BorderColor="#1d3f72"
                  borderRadius={20}
                  handleOnclick={handleNextStep}
                  pending={false}
                />
              ) : null}

              {isChecked ? (
                <ButtonSubmit
                  Title="Submit"
                  BackgroundColor="#1d3f72"
                  ColorText="#fff"
                  BorderColor="#1d3f72"
                  borderRadius={20}
                  pending={pending}
                />
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
