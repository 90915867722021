import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";
import { useFormik } from "formik";
import { resetPasswordSchema } from "../../lib/utils/dataValidation";
import logoKago from "../../../src/assets/images/kago-logo.png";
import usePost from "../../../src/lib/Hooks/usePost";
import { ButtonSubmit } from "../../components/Common/Buttons";

const Reset = (props) => {
  const navigate = useNavigate();
  const { token } = useParams();

  const { execute, pending, status } = usePost();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values) => {
      const Method = "PATCH";
      const endPoint = `auth/resetPassword/${token}`;
      const raw = JSON.stringify(values);
      await execute(endPoint, raw, Method, true);
    },
  });

  useEffect(() => {
    if (status === "success") {
      navigate("/reset");
    }
  }, [status]);

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <CardBody className="pt-0">
                <div className="d-flex justify-content-center">
                  <img src={logoKago} alt="Kago logo" height="34" />
                </div>
                <div className="p-2">
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="mb-3">
                      <Label>New Password</Label>
                      <Input
                        name="password"
                        type="password"
                        placeholder="Enter new password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password}
                        invalid={
                          validation.touched.password &&
                          validation.errors.password
                        }
                      />
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback>
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Confirm Password</Label>
                      <Input
                        name="passwordConfirm"
                        type="password"
                        placeholder="Confirm new password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.passwordConfirm}
                        invalid={
                          validation.touched.passwordConfirm &&
                          validation.errors.passwordConfirm
                        }
                      />
                      {validation.touched.passwordConfirm &&
                      validation.errors.passwordConfirm ? (
                        <FormFeedback>
                          {validation.errors.passwordConfirm}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <Row className="mb-3">
                      <Col className="text-end">
                        <ButtonSubmit
                          Title="Reset Password"
                          BackgroundColor="#33a6cd"
                          ColorText="#fff"
                          BorderColor=""
                          borderRadius="20px"
                          handleOnclick={() => {
                            validation.handleSubmit();
                          }}
                          pending={pending}
                        />
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Reset;
