import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Container, Wrapper, Title } from "./Style";
import MyDepartmentalChart from "../../../Common/MyDepartmentalChart";
import LatestReview from "./LatestReview";
// import MonthlyReviewChart from "../../../Common/MonthlyReviewChart";
import ManagerChart from "../../../Common/ManagerChart";

// myDeptOverall;
const MonthlyDepartmentAnalysisis = ({ myDeptOverall }) => {
  return (
    <React.Fragment>
      <Row>
        <Col md={6}>
          <Card style={{ ...Container, height: 500 }}>
            <CardBody style={Wrapper}>
              <div style={Title}>Department Overall Performance</div>
              <div>
                <MyDepartmentalChart myDeptOverall={myDeptOverall} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <Card style={{ ...Container, height: 500 }}>
            <CardBody style={Wrapper}>
              <div style={Title}>Department Review(s)</div>
              <div>
                <LatestReview />
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={12}>
          <div className="mt-4" style={Title}>
            Employee Monthly Review
          </div>
          <div>
            <ManagerChart />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MonthlyDepartmentAnalysisis;
