import React, { useState } from "react";
import {
  dummyDataDue,
  dummyDataOutstanding,
  dummyDataClose,
} from "../../../../lib/utils/dummyData";

import { lastest } from "./Style";

const LatestReview = () => {
  // Map tabs to their corresponding payment arrays
  const reviewsMap = {
    Due: dummyDataDue,
    Outstanding: dummyDataOutstanding,
    Close: dummyDataClose,
  };
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState("Due");
  const itemsPerPage = 5;
  const tabs = ["Due", "Outstanding", "Close"];

  // Get current tab's payments
  const currentTabReviews = reviewsMap[activeTab] || [];
  const totalPages = Math.ceil(currentTabReviews.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPayments = currentTabReviews.slice(startIndex, endIndex);

  // Reset page when changing tabs
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setPage(1);
  };
  return (
    <div className="w-100">
      <div style={lastest.container}>
        <div style={lastest.tabsContainer}>
          {tabs.map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabChange(tab)}
              style={{
                ...lastest.tab,
                ...(activeTab === tab
                  ? lastest.activeTab
                  : lastest.inactiveTab),
              }}
            >
              {tab}
            </button>
          ))}
        </div>

        <div style={lastest.tableContainer}>
          <table style={lastest.table}>
            <thead>
              <tr>
                <th style={lastest.th}>Full name</th>
                <th style={lastest.th}>Department</th>
                <th style={{ ...lastest.th, textAlign: "right" }}>Due Date</th>
              </tr>
            </thead>
            <tbody>
              {currentPayments.length > 0 ? (
                currentPayments.map((employee, i) => (
                  <tr key={i}>
                    <td style={lastest.td}>
                      {employee.userId.firstName} {employee.userId.lastName}
                    </td>
                    <td style={{ ...lastest.td, ...lastest.clientName }}>
                      Marketing
                    </td>
                    <td style={{ ...lastest.td, ...lastest.amount }}>
                      {employee.status}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" style={lastest.emptyState}>
                    No review found for this status
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div style={lastest.pContainer}>
          {currentPayments.length > 0 && (
            <div style={lastest.pagination}>
              <button
                onClick={() => page > 1 && setPage(1)}
                disabled={page === 1}
                style={{
                  ...lastest.pageButton,
                  ...(page === 1 ? lastest.disabledButton : {}),
                }}
              >
                «
              </button>
              <button
                onClick={() => page > 1 && setPage(page - 1)}
                disabled={page === 1}
                style={{
                  ...lastest.pageButton,
                  ...(page === 1 ? lastest.disabledButton : {}),
                }}
              >
                ‹
              </button>
              {[...Array(totalPages)].map((_, i) => (
                <button
                  key={i + 1}
                  onClick={() => setPage(i + 1)}
                  style={{
                    ...lastest.pageButton,
                    ...(page === i + 1 ? lastest.activePageButton : {}),
                  }}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => page < totalPages && setPage(page + 1)}
                disabled={page === totalPages}
                style={{
                  ...lastest.pageButton,
                  ...(page === totalPages ? lastest.disabledButton : {}),
                }}
              >
                ›
              </button>
              <button
                onClick={() => page < totalPages && setPage(totalPages)}
                disabled={page === totalPages}
                style={{
                  ...lastest.pageButton,
                  ...(page === totalPages ? lastest.disabledButton : {}),
                }}
              >
                »
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LatestReview;
