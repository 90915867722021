import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import DataTable from "react-data-table-component";
import useCustomFetchQuery from "../../../../lib/Hooks/useCustomFecthQuery";
import { AiFillEye, AiFillSignature } from "react-icons/ai";
import { employeeTblTitle, employeeTbl } from "./Style";
import OffCanvasFull from "../../../Common/OffCanvas/OffCanvasFull";
import ComplteteReview from "./CompleteReview";
import ViewReview from "./ViewReview";
import SignReview from "./SignReview";
import { convertReviewsDate } from "../../../../lib/utils/convertReviewsDate";
import { employeeInitialData } from "../../../../lib/utils/employeeInitialData";
import { getScore } from "../../../../lib/utils/getScore";

const MyReviewTable = ({
  userID,
  token,
  search,
  organizationID,
  departmentID,
}) => {
  const [reviewDetails, setReviewDetails] = useState(null);
  const [isViewEvaluation, setIsViewEvaluation] = useState(false);
  const [isSignEvaluation, setIsSignEvaluation] = useState(false);
  const [isCompleteEvatuation, setIsCompleteEvatuation] = useState(false);

  const {
    data: myReviews,
    error,
    isLoading,
  } = useCustomFetchQuery(
    ["MyReviews", organizationID],
    `${process.env.REACT_APP_APIKEY}assessments/user/${userID}`,
    token,
    { enabled: !!organizationID }
  );

  const toggleViewEvaluation = (details) => {
    setReviewDetails(details);
    setIsViewEvaluation(!isViewEvaluation);
  };
  const toggleViewNotSigned = (details) => {
    // console.log("hello...");
    setReviewDetails(details);
    setIsViewEvaluation(!isViewEvaluation);
  };

  const toggleSignEvaluation = (details) => {
    setReviewDetails(details);
    setIsSignEvaluation(!isSignEvaluation);
  };

  const toggleCompleteEvaluation = (details) => {
    setReviewDetails(details);
    setIsCompleteEvatuation(!isCompleteEvatuation);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Full name</span>,
      cell: (row) => {
        return (
          <div className="w-100">
            {row?.userId?.firstName} {row?.userId?.lastName}
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Created Date</span>,
      selector: (row) => convertReviewsDate(row?.createdAt),
    },
    {
      name: <span className="font-weight-bold fs-13">Due Date</span>,
      selector: (row) => row?.dueDate,
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      cell: (row) => {
        return (
          <div className="w-100">
            <span
              className={`${
                row?.status === "Overdue"
                  ? `text-danger`
                  : row?.status === "Due"
                  ? `text-warning`
                  : row?.status === "Pending Review"
                  ? `text-secondary`
                  : row?.status === "Under Review"
                  ? `text-info`
                  : row?.status === "Closed"
                  ? `text-success`
                  : row?.status === "Expired"
                  ? `text-dark`
                  : null
              }`}
              style={{
                width: 96,
                height: 32,
                borderRadius: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 13,
                fontWeight: "bolder",
                color: "#fefefe",
              }}
            >
              {row?.status}
            </span>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Score</span>,
      cell: (row) => {
        let score = getScore(row?.assessment?.manager?.total);
        return (
          <span
            style={{
              fontSize: 17,
              fontWeight: "bolder",
              color: score < 3 ? "#ff0000" : score > 3 ? "#08b36c" : "#fcb92c",
              paddingLeft: 10,
            }}
          >
            {getScore(row?.assessment?.manager?.total)}
          </span>
        );
      },
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              {row?.status === "Closed" ? (
                <AiFillEye
                  size={23}
                  className="mx-1"
                  onClick={() => toggleViewEvaluation(row)}
                />
              ) : row?.status === "Under Review" ? (
                <AiFillEye
                  size={23}
                  className="mx-1"
                  onClick={() => toggleViewEvaluation(row)}
                />
              ) : row?.status === "Not Completed" ? (
                <AiFillEye
                  size={23}
                  className="mx-1"
                  onClick={() => toggleViewEvaluation(row)}
                />
              ) : row?.status === "Pending Review" ? (
                <AiFillEye
                  size={23}
                  className="mx-1"
                  color="#D0D0D0"
                  onClick={() => {}}
                />
              ) : null}
            </div>

            <div>
              {row?.status === "Due" ? (
                <button
                  className="btn-start-review"
                  onClick={() => toggleCompleteEvaluation(row?.id)}
                >
                  Start review
                </button>
              ) : row?.status === "Under Review" ? (
                <AiFillSignature
                  size={22}
                  className="mx-1"
                  onClick={() => toggleSignEvaluation(row)}
                />
              ) : (
                <AiFillSignature
                  size={22}
                  className="mx-1"
                  color="#D0D0D0"
                  onClick={() => {}}
                />
              )}
            </div>
          </div>
        );
      },
    },
  ];

  // const filterEmployee = (employeeData, searchQuery) => {
  //   if (!searchQuery) return employeeData;
  //   return employeeData?.filter((item) =>
  //     [item?.fullName].some((field) =>
  //       field?.toLowerCase().includes(searchQuery.toLowerCase())
  //     )
  //   );
  // };
  // const filteredEmployeeTable = filterEmployee(myReviews?.data, search);

  const data = employeeInitialData.concat(myReviews?.data || []);

  return (
    <div className="mt-3 mb-5 w-100">
      <Card>
        <CardBody style={employeeTbl}>
          <div style={employeeTblTitle}>Employee Review</div>
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="300px"
            columns={columns}
            responsive
            data={data}
            pagination
          />
        </CardBody>
      </Card>

      <OffCanvasFull
        isOffcanvasOpen={isViewEvaluation}
        title="View Evaluation Report"
        body={
          <ViewReview
            setIsViewEvaluation={setIsViewEvaluation}
            reviewDetails={reviewDetails}
            token={token}
          />
        }
        toggleSaveChanges={() => toggleViewEvaluation()}
        directionOffCanvas="end"
        widthOffCanvas={"100%"}
        bgColor={"#fff"}
        bgClose="#33A6CD"
        closeText={"#fff"}
      />

      <OffCanvasFull
        isOffcanvasOpen={isCompleteEvatuation}
        title="Complete your Evaluation"
        body={
          <ComplteteReview
            setIsCompleteEvatuation={setIsCompleteEvatuation}
            token={token}
            userID={userID}
            organizationID={organizationID}
            departmentID={departmentID}
          />
        }
        toggleSaveChanges={() => toggleCompleteEvaluation()}
        directionOffCanvas="end"
        widthOffCanvas={"100%"}
      />
      <OffCanvasFull
        isOffcanvasOpen={isSignEvaluation}
        title="Sign Evaluation"
        body={
          <SignReview
            setIsSignEvaluation={setIsSignEvaluation}
            reviewDetails={reviewDetails}
            token={token}
            organizationID={organizationID}
          />
        }
        toggleSaveChanges={() => toggleSignEvaluation()}
        directionOffCanvas="end"
        widthOffCanvas={"100%"}
        bgColor={"#fff"}
        bgClose="#33A6CD"
        closeText={"#fff"}
      />
    </div>
  );
};
export default MyReviewTable;
