import React from "react";
import { LiaUser } from "react-icons/lia";

const ViewProfile = ({ userDetails }) => {
  return (
    <div className="vProfile-container">
      <div className="vProfile-header">
        <div className="vProfile-header-picture">
          <div className="vProfile-header-picture-container">
            {userDetails?.photo ? (
              <img src={userDetails?.photo} alt="profile" />
            ) : (
              <div className="vProfile-header-picture-container-icon">
                <LiaUser size={100} color="#fff" />
              </div>
            )}
          </div>
        </div>
        <div className="vProfile-header-details">
          <div className="w-100">
            <div className="vProfile-header-details-name">
              {userDetails?.firstName} {userDetails?.lastName}
            </div>
            <div className="vProfile-header-details-email">
              {userDetails?.email}
            </div>
          </div>
        </div>
      </div>
      <div className="vProfile-separator"></div>
      <div className="vProfile-body">
        <div className="vProfile-body-section-title">Personal Info</div>
        <div className="vProfile-body-section-body">
          <div className="vProfile-body-section-body-title">First name : </div>
          <div className="vProfile-body-section-body-text">
            {userDetails?.firstName}
          </div>
        </div>
        <div className="vProfile-body-section-body">
          <div className="vProfile-body-section-body-title">Last name : </div>
          <div className="vProfile-body-section-body-text">
            {userDetails?.lastName}
          </div>
        </div>
        <div className="vProfile-body-section-body">
          <div className="vProfile-body-section-body-title">Email : </div>
          <div className="vProfile-body-section-body-text">
            {userDetails?.email}
          </div>
        </div>
        <div className="vProfile-body-section-body">
          <div className="vProfile-body-section-body-title">Phone number: </div>
          <div className="vProfile-body-section-body-text">
            {userDetails?.phoneNumber}
          </div>
        </div>
        <div className="vProfile-body-section-body">
          <div className="vProfile-body-section-body-title">Department: </div>
          <div className="vProfile-body-section-body-text">{""}</div>
        </div>
        <div className="vProfile-body-section-body">
          <div className="vProfile-body-section-body-title">Role: </div>
          <div className="vProfile-body-section-body-text">
            {userDetails?.position}
          </div>
        </div>
        <div className="vProfile-body-section-body">
          <div className="vProfile-body-section-body-title">Bio: </div>
          <div className="vProfile-body-section-body-text">
            {userDetails?.bio}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProfile;
