import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const UserForm = ({ inputs, setInputs, setNext, watch, register, errors }) => {
  const watchFields = watch(["firstName", "lastName", "email"]);

  useEffect(() => {
    const [firstName, lastName, email] = watchFields;
    if (firstName && lastName && email && inputs.phoneNumber) {
      setNext(true);
    } else {
      setNext(false);
    }
  }, [errors, inputs.phoneNumber, setNext]);

  return (
    <>
      <Row>
        <Col md={6}>
          <label>
            First name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            name="firstName"
            value={inputs.firstName}
            placeholder="Enter first name"
            style={{ borderRadius: 7 }}
            className={`mb-3 form-control fromInput ${
              errors.firstName ? "border border-danger" : ""
            }`}
            {...register("firstName")}
          />
          {errors.firstName && (
            <p className="text-danger">{errors.firstName.message}</p>
          )}
        </Col>
        <Col md={6}>
          <label>
            Last name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            name="lastName"
            value={inputs.lastName}
            placeholder="Enter last name"
            style={{ borderRadius: 7 }}
            className={`mb-3 form-control fromInput ${
              errors.lastName ? "border border-danger" : ""
            }`}
            {...register("lastName")}
          />
          {errors.lastName && (
            <p className="text-danger">{errors.lastName.message}</p>
          )}
        </Col>
        <Col md={6}>
          <label>
            Email <span className="text-danger">*</span>
          </label>
          <input
            type="email"
            name="email"
            value={inputs.email}
            placeholder="Enter email"
            style={{ borderRadius: 7 }}
            className={`mb-3 form-control fromInput ${
              errors.email ? "border border-danger" : ""
            } `}
            {...register("email")}
          />
          {errors.email && (
            <p className="text-danger">{errors.email.message}</p>
          )}
        </Col>
        <Col md={6}>
          <label>
            Phone Number <span className="text-danger">*</span>
          </label>
          <div
            className={`phone-input-container ${
              inputs.phoneNumber.length < 12 && inputs.phoneNumber !== null
                ? "border border-danger"
                : ""
            }`}
          >
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry="ZA"
              value={inputs.phoneNumber ? inputs.phoneNumber : null}
              onChange={(value) =>
                setInputs({ ...inputs, phoneNumber: value ? value : 0 })
              }
              className="custom-phone-input"
            />
          </div>
          {inputs.phoneNumber.length < 12 && inputs.phoneNumber !== null ? (
            <p className="text-danger"> Required valid phone number </p>
          ) : null}
        </Col>
        <Col md={6}>
          <label>
            Company Name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            name="companyName"
            value={inputs.companyName}
            placeholder="Enter your company name"
            style={{ borderRadius: 7 }}
            className={`mb-3 form-control fromInput ${
              errors.companyName ? "border border-danger" : ""
            }`}
            {...register("companyName")}
          />
          {errors.companyName && (
            <p className="text-danger">{errors.companyName.message}</p>
          )}
        </Col>
        <Col md={6}>
          <label>
            Company Size <span className="text-danger">*</span>
          </label>
          <input
            type="number"
            name="companySize"
            value={inputs.companySize}
            style={{ borderRadius: 7 }}
            className="mb-3 form-control fromInput"
            {...register("companySize")}
          />
          {errors.companySize && (
            <p className="text-danger">{errors.companySize.message}</p>
          )}
        </Col>
      </Row>
    </>
  );
};

export default UserForm;
