import * as Yup from "yup";
const passwordRules =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{10,}$/;

export const loginSchema = Yup.object({
  email: Yup.string()
    .required("Email address is required.")
    .email("Invalid email address."),
  password: Yup.string().required("Password is rquired"),
});
export const forgotSchema = Yup.object({
  email: Yup.string()
    .required("Email address is required.")
    .email("Invalid email address."),
});

export const resetPasswordSchema = Yup.object({
  password: Yup.string()
    .required("Password is required.")
    .matches(
      passwordRules,
      "Password must be at least 10 characters long and include an uppercase letter, a lowercase letter, a number, and a special character."
    ),
  passwordConfirm: Yup.string()
    .required("Confirm password is required.")
    .oneOf([Yup.ref("password")], "Passwords must match."),
});

export const registerOwnerSchema = Yup.object({
  firstName: Yup.string()
    .required("First name is required.")
    .matches(/^[a-zA-Z ]*$/, "No special characters are allowed.")
    .min(3, "First name must be between 3 and 30 characters.")
    .max(30, "First name must be between 3 and 30 characters."),
  lastName: Yup.string()
    .required("Last name is required.")
    .matches(/^[a-zA-Z ]*$/, "No special characters are allowed.")
    .min(3, "Last name must be between 3 and 30 characters.")
    .max(30, "Last name must be between 3 and 30 characters."),
  email: Yup.string()
    .required("Email address is required.")
    .email("Invalid email address."),
  companyName: Yup.string()
    .required("Company name is required.")
    .min(2, "Company name must be between 2 and 50 characters.")
    .max(60, "Company name must be between 2 and 50 characters."),
  companySize: Yup.string()
    .required("Company size is required.")
    .min(1, "Company size must be at least 1.")
    .max(10000, "Company size must be realistic."),
  password: Yup.string()
    .required("Password is required.")
    .matches(
      passwordRules,
      "Password must be at least 10 characters long and include an uppercase letter, a lowercase letter, a number, and a special character."
    ),
  passwordConfirm: Yup.string()
    .required("Confirm password is required.")
    .oneOf([Yup.ref("password")], "Passwords must match."),
});

export const registerEmployeeSchema = Yup.object({
  // department: Yup.string().required("Department is required."),
  // roles: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       type: Yup.string().required("Role type is required."),
  //       period: Yup.string(),
  //     })
  //   )
  //   .required("Roles are required."),

  initials: Yup.string()
    .required("Initials are required.")
    .max(3, "Maximum 3 characters"),
  firstName: Yup.string().required("First name is required."),
  firstName: Yup.string().required("First name is required."),
  lastName: Yup.string().required("Last name is required."),
  dateOfBirth: Yup.date()
    .nullable()
    .transform(
      (value, originalValue) => (originalValue === "" ? null : value) // Transform empty string to null
    )
    .typeError("Please enter a valid date of birth") // Custom error for invalid dates
    .required("Date of Birth is required") // Custom error for missing date
    .max(new Date(), "Date of Birth cannot be in the future") // Prevent future dates
    .test(
      "min-age",
      "You must be at least 18 years old",
      (value) => value && new Date().getFullYear() - value.getFullYear() >= 18
    ),
  // employeeNumber: Yup.string().required("ID/Passport is required."),
  identificationNumber: Yup.string().required("ID/Passport is required."),
  physicalAddress: Yup.string().required("Physical address is required."),
  suburb: Yup.string().required("Suburb is required."),
  city: Yup.string().required("City is required."),
  postalCode: Yup.string()
    .required("Postal code is required.")
    .matches(/^\d{4,6}$/, "Postal code must be 4-6 digits."),
  // country: Yup.string().required("Country is required."),
});

export const contactUsSchema = Yup.object({
  firstName: Yup.string()
    .required("First name is required.")
    .matches(/^[a-zA-Z ]*$/, "No special characters are allowed.")
    .min(3, "First name must be between 3 and 30 characters.")
    .max(30, "First name must be between 3 and 30 characters."),
  lastName: Yup.string()
    .required("Last name is required.")
    .matches(/^[a-zA-Z ]*$/, "No special characters are allowed.")
    .min(3, "Last name must be between 3 and 30 characters.")
    .max(30, "Last name must be between 3 and 30 characters."),
  email: Yup.string()
    .required("Email address is required.")
    .email("Invalid email address."),
  numberOfEmployees: Yup.string()
    .required("Company size is required.")
    .min(1, "number of employees must be at least 1.")
    .max(1000000, "number of employees must not be above 1000000."),
});
