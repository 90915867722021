import React from "react";
import { useNavigate } from "react-router-dom";
import AdminData from "./AdminData";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-black-white.png";

const AdminMenu = ({ props }) => {
  const navigate = useNavigate();
  return (
    <div id="sidebar-menu">
      <div className="d-flex  mb-5">
        <div style={{ width: "70%", height: "70%" }}>
          <img
            src={logo}
            alt="logo-picture"
            className="w-100 h-100"
            onClick={() => navigate("/dashboard")}
          />
        </div>
      </div>
      <ul className="metismenu list-unstyled" id="side-menu-item">
        {(AdminData || []).map((item, key) => (
          <React.Fragment key={key}>
            {item.isMainMenu ? (
              <li className="menu-title" style={{ color: "#fff" }}>
                {props.t(item.label)}
              </li>
            ) : (
              <li key={key}>
                <Link
                  to={item.url ? item.url : "/#"}
                  className={
                    item.issubMenubadge || item.isHasArrow ? " " : "has-arrow"
                  }
                >
                  <i className={item.icon} style={{ marginRight: "5px" }}></i>
                  {item.issubMenubadge && (
                    <span
                      className={"badge rounded-pill float-end " + item.bgcolor}
                    >
                      {" "}
                      {item.badgeValue}{" "}
                    </span>
                  )}
                  <span>{props.t(item.label)}</span>
                </Link>
                {item.subItem && (
                  <ul className="sub-menu" style={{ color: "#fff" }}>
                    {item.subItem.map((item, key) => (
                      <li key={key}>
                        <Link
                          to={item.link}
                          className={item.subMenu && "has-arrow waves-effect"}
                        >
                          {props.t(item.sublabel)}
                        </Link>
                        {item.subMenu && (
                          <ul className="sub-menu">
                            {item.subMenu.map((item, key) => (
                              <li key={key}>
                                <Link to="#">{props.t(item.title)}</Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default AdminMenu;
