import React from "react";
import { Progress } from "reactstrap";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo-black-white.png";
import { LiaUser } from "react-icons/lia";

const EmployeeMenu = ({ userBio, userPhoto, organizationName }) => {
  const navigate = useNavigate();
  const obj = JSON.parse(localStorage.getItem("authHC"));
  const userDetails = obj?.data?.data;
  const role = obj?.data?.data?.roles[0];

  return (
    <div id="sidebar-menu">
      <div className="d-flex justify-content-center mb-5">
        <div style={{ width: "70%", height: "70%" }}>
          <img
            src={logo}
            alt="logo-picture"
            className="w-100 h-100"
            onClick={() => navigate("/dashboard")}
          />
        </div>
      </div>
      <ul className="metismenu list-unstyled" id="side-menu-item">
        <div className="d-flex w-100 h-100 justify-content-center">
          {userPhoto ? (
            <img
              src={userPhoto}
              alt="employee-image"
              style={{ width: 105, height: 105, borderRadius: "50%" }}
            />
          ) : (
            <div
              style={{
                width: 105,
                height: 105,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#33a6cd",
                borderRadius: "50%",
              }}
            >
              <LiaUser size={70} color="#fff" />
            </div>
          )}
        </div>
        <div
          className="mt-3 w-100 text-center"
          style={{ fontWeight: "bolder", fontSize: "18px" }}
        >
          {userDetails?.firstName} {userDetails?.lastName}
        </div>
        <div
          className="mt-3 w-100 text-center"
          style={{
            color: "#33a6cd",
          }}
        >
          {organizationName} / {role?.type}
        </div>
        {userBio && (
          <div className="mt-3 w-100 d-flex justify-content-center">
            <div
              style={{
                width: "85%",
                margin: "0px auto",
                textWrap: "stable",
                textAlign: "center",
                fontWeight: "lighter",
                wordSpacing: 0,
              }}
            >
              {userBio}
            </div>
          </div>
        )}

        <div className="w-100 mt-3 d-flex justify-content-center">
          {/* <div
            style={{
              width: "85%",
              textAlign: "justify",
              fontWeight: "bolder",
              fontSize: "16px",
            }}
          >
            Values
          </div> */}
        </div>
        {/* <div className="mt-2 w-100 d-flex justify-content-center">
          <div style={{ width: "85%", textAlign: "justify", fontSize: 12 }}>
            Quality of Work
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <div style={{ width: "85%", textAlign: "justify", fontSize: 12 }}>
            <Progress color="success" value={100}></Progress>
          </div>
        </div>
        <div className="mt-2 w-100 d-flex justify-content-center">
          <div style={{ width: "85%", textAlign: "justify", fontSize: 12 }}>
            Job Knowledge
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <div style={{ width: "85%", textAlign: "justify", fontSize: 12 }}>
            <Progress color="success" value={90}></Progress>
          </div>
        </div>

        <div className="mt-2 w-100 d-flex justify-content-center">
          <div style={{ width: "85%", textAlign: "justify", fontSize: 12 }}>
            Cramming
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <div style={{ width: "85%", textAlign: "justify", fontSize: 12 }}>
            <Progress color="warning" value={65}></Progress>
          </div>
        </div>

        <div className="mt-2 w-100 d-flex justify-content-center">
          <div style={{ width: "85%", textAlign: "justify", fontSize: 12 }}>
            Laziness
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <div style={{ width: "85%", textAlign: "justify", fontSize: 12 }}>
            <Progress color="danger" value={20}></Progress>
          </div>
        </div> */}
      </ul>
    </div>
  );
};

export default EmployeeMenu;
