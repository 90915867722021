export const getScore = (score) => {
  if (score == null) return null;
  const calculatedScore = score * 0.05;
  const roundedScore =
    calculatedScore % 1 > 0.5
      ? Math.ceil(calculatedScore)
      : Math.floor(calculatedScore);

  return roundedScore;
};
