import React, { useState } from "react";
import { Progress, Card, CardBody } from "reactstrap";
import DataTable from "react-data-table-component";
import { AiFillEye } from "react-icons/ai";
import { employeeTblTitle, employeeTbl } from "../../../Common/TableStyle";
import { convertReviewsDate } from "../../../../lib/utils/convertReviewsDate";
import { getScore } from "../../../../lib/utils/getScore";
import OffCanvasFull from "../../../Common/OffCanvas/OffCanvasFull";
import ViewReview from "../../Dashboard/Employee/ViewReview";

const EmployeePerformanceTable = ({ employeeReviews }) => {
  const [reviewDetails, setReviewDetails] = useState(null);
  const [isViewEvaluation, setIsViewEvaluation] = useState(false);
  const toggleViewEvaluation = (details) => {
    setReviewDetails(details);
    setIsViewEvaluation(!isViewEvaluation);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Full name</span>,
      cell: (row) => {
        return (
          <div className="w-100">
            {row?.userId?.firstName} {row?.userId?.lastName}
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Created Date</span>,
      selector: (row) => convertReviewsDate(row?.createdAt),
    },
    // {
    //   name: <span className="font-weight-bold fs-13">Due Date</span>,
    //   selector: (row) => row?.dueDate,
    // },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      cell: (row) => {
        return (
          <div className="w-100">
            <span
              className={`${
                row?.status === "Overdue"
                  ? `text-danger`
                  : row?.status === "Pending"
                  ? `text-warning`
                  : row?.status === "In Review"
                  ? `text-secondary`
                  : row?.status === "Not Signed"
                  ? `text-info`
                  : row?.status === "Submitted"
                  ? `text-success`
                  : row?.status === "Expired"
                  ? `text-dark`
                  : null
              }`}
              style={{
                width: 96,
                height: 32,
                borderRadius: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 13,
                fontWeight: "bolder",
                color: "#fefefe",
              }}
            >
              {row?.status}
            </span>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Score</span>,
      cell: (row) => {
        let score = getScore(row?.assessment?.manager?.total);
        return (
          <span
            style={{
              fontSize: 17,
              fontWeight: "bolder",
              color: score < 3 ? "#ff0000" : score > 3 ? "#08b36c" : "#fcb92c",
              marginLeft: 10,
            }}
          >
            {getScore(row?.assessment?.manager?.total)}
          </span>
        );
      },
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <AiFillEye
                size={20}
                className="mx-1"
                onClick={() => toggleViewEvaluation(row)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  // const filterEmployee = (employeeData, searchQuery) => {
  //   if (!searchQuery) return employeeData;
  //   return employeeData?.filter((item) =>
  //     [item?.fullName].some((field) =>
  //       field?.toLowerCase().includes(searchQuery.toLowerCase())
  //     )
  //   );
  // };
  // const filteredEmployeeTable = filterEmployee(employee, search);

  const filteredData =
    employeeReviews?.data
      ?.filter((item) =>
        ["Overdue", "Submitted", "Expired"]?.includes(item?.status)
      )
      ?.reverse() || [];

  const data = filteredData?.reverse() || [];

  return (
    <div className="mt-3 mb-5 w-100">
      <Card style={employeeTbl}>
        <CardBody>
          <div style={employeeTblTitle}>Employee Performance</div>
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="300px"
            columns={columns}
            responsive
            data={data}
            pagination
          />
        </CardBody>
      </Card>
      <OffCanvasFull
        isOffcanvasOpen={isViewEvaluation}
        title="View Evaluation Report"
        body={
          <ViewReview
            setIsViewEvaluation={setIsViewEvaluation}
            reviewDetails={reviewDetails}
          />
        }
        toggleSaveChanges={() => toggleViewEvaluation()}
        directionOffCanvas="end"
        widthOffCanvas={"100%"}
        bgColor={"#fff"}
        bgClose="#33A6CD"
        closeText={"#fff"}
      />
    </div>
  );
};
export default EmployeePerformanceTable;
