import PropTypes from "prop-types";
import React, { useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

// Redux imports
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import usePost from "../../lib/Hooks/usePost";
import { useFormik } from "formik";
import { forgotSchema } from "../../lib/utils/dataValidation";

import logoKago from "../../../src/assets/images/kago-logo.png";
import { ButtonSubmit } from "../../components/Common/Buttons/index";

// ForgotPassword component definition
const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const { execute, pending, status } = usePost();

  document.title = "Forgot Password | Kago Human Capital";

  // Formik setup for form validation and submission
  const validation = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotSchema,
    onSubmit: async (values) => {
      const Method = "POST",
        endPoint = "auth/forgotPassword",
        token = null;
      const raw = JSON.stringify(values);
      await execute(endPoint, raw, Method, true, token);
    },
  });
  useEffect(() => {
    if (status === "success") {
      navigate("/sent-email");
    }
  }, [status]);

  return (
    <React.Fragment>
      <div
        className="account-pages my-5 pt-sm-5"
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                {/* Banner Section */}
                <div
                  className="bg-primary"
                  style={{ backgroundColor: "#33a6cd", padding: "40px 20px" }}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="text-white p-4">
                        <h5 className="text-white">Forgot Your Password?</h5>
                        <p>
                          Please enter your email address to receive a password
                          reset link.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="d-flex justify-content-center">
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoKago}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {/* Form Section */}
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter your email"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.email}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <ButtonSubmit
                            Title="Send Reset Link"
                            BackgroundColor="#33a6cd"
                            ColorText="#fff"
                            BorderColor=""
                            borderRadius="5px"
                            handleOnclick={() => {
                              validation.handleSubmit();
                            }}
                            pending={pending}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember your password?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login here
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

// PropTypes for type-checking
ForgotPassword.propTypes = {
  history: PropTypes.object,
};

// Export the component with router props
export default withRouter(ForgotPassword);
