import React from "react";
import useCustomFetchQuery from "../../../lib/Hooks/useCustomFecthQuery";
import GreetingManager from "../../../components/Pages/Dashboard/Manager/GreetingManager";
import ManangerTopMenu from "../../../components/Pages/Dashboard/Manager/ManangerTopMenu";
import MonthlyDepartmentAnalysisis from "../../../components/Pages/Dashboard/Manager/MonthlyDepartmentAnalysis";

const Manager = ({
  Navigate,
  firstName,
  userFullName,
  organizationID,
  departmentID,
  token,
  todayDate,
}) => {
  const {
    data: myDeptOverall,
    error,
    isLoading,
  } = useCustomFetchQuery(
    ["DeptAnalytics", departmentID],
    `${process.env.REACT_APP_APIKEY}reports/${departmentID}/department`,
    token,
    { enabled: !!departmentID }
  );
  return (
    <React.Fragment>
      <GreetingManager firstName={firstName} todayDate={todayDate} />
      <ManangerTopMenu />

      <MonthlyDepartmentAnalysisis myDeptOverall={myDeptOverall} />
    </React.Fragment>
  );
};

export default Manager;
