import React from "react";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";

function MessageReset() {
  const Navigate = useNavigate();
  return (
    <div className="message-page">
      <div className="message-card-content">
        <div className="message-card-icon">
          <FaCheck size={27} color="white" />
        </div>
        <h2>Password has been reset !</h2>
        <p>Please below to login</p>

        <button
          className="message-card-button"
          onClick={() => {
            Navigate(`/login`);
          }}
        >
          OK
        </button>
      </div>
    </div>
  );
}

export default MessageReset;
