import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
// redux
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../../store/actions";
import { Link } from "react-router-dom";
import { Row, Col, Alert, Form, Input, FormFeedback, Label } from "reactstrap";
import { createSelector } from "reselect";
import usePost from "../../../lib/Hooks/usePost";
// Formik validation
import { useFormik } from "formik";
import { loginSchema } from "../../../lib/utils/dataValidation";

import logoKago from "../../../assets/images/kago-logo.png";
import ButtonSubmit from "../../Common/Buttons/ButtonSubmit";

const LoginForm = ({ props }) => {
  const { execute, pending, data, status } = usePost();

  const [captchaValue, setCaptchaValue] = useState(null);
  const onChangeRecaptcha = (value) => {
    setCaptchaValue(value);
  };

  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      const Method = "POST",
        endPoint = "auth/login",
        token = null;
      const raw = JSON.stringify(values);
      await execute(endPoint, raw, Method, "Successful logged in", token);
    },
  });

  const loginpage = createSelector(
    (state) => state.login,
    (state) => ({
      error: state.error,
    })
  );
  const { error } = useSelector(loginpage);

  useEffect(() => {
    if (status === "success") {
      dispatch(loginUser(data?.data, props?.router?.navigate));
    }
  }, [status]);

  return (
    <>
      <div
        style={{
          width: "100px",
          height: "60px",
          display: "flex",
          marginTop: "2%",
        }}
      >
        <img
          src={logoKago}
          alt="hc-logo"
          style={{
            objectFit: "contain",
          }}
        />
      </div>

      <div className="w-100 mt-3">
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          {error ? (
            <Alert color="danger">
              <div>{error}</div>
            </Alert>
          ) : null}
          <Row>
            <Col md={12}>
              <div className="mb-4">
                <Label className="form-label">Email</Label>
                <Input
                  name="email"
                  className="form-control"
                  placeholder="Enter email"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    <div>{validation.errors.email}</div>
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Password</Label>
                <Input
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.password && validation.errors.password
                      ? true
                      : false
                  }
                />
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">
                    <div> {validation.errors.password} </div>
                  </FormFeedback>
                ) : null}
              </div>
              <div className="w-100 mb-2 d-flex justify-content-end">
                <Link to="/forgot-password" className="text-muted">
                  <i className="mdi mdi-lock"></i> Forgot your password?
                </Link>
              </div>

              <div className="w-100 d-grid">
                <ButtonSubmit
                  Title="Login"
                  BackgroundColor="#33a6cd"
                  ColorText="#fff"
                  BorderColor=""
                  borderRadius="20px"
                  handleOnclick={() => {
                    validation.handleSubmit();
                  }}
                  pending={pending}
                />
              </div>
              <div className="authentication-recaptcha">
                <ReCAPTCHA
                  sitekey="6LfhLWEqAAAAAHBXXHASkdp4g7VbSsZXWNjxQbBY"
                  onChange={onChangeRecaptcha}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default LoginForm;
