import React, { useState, useEffect } from "react";
import useCustomFetchQuery from "../../lib/Hooks/useCustomFecthQuery";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import withRouter from "../../components/Common/withRouter";
import Breadcrumb from "../../components/Common/Breadcrumb";
import ViewProfile from "../../components/Pages/User-Profile/ViewProfile";
import UserPersonalInfo from "../../components/Pages/User-Profile/UserPersonalInfo";
import UpdatePassword from "../../components/Pages/User-Profile/UpdatePassword";
import DeleteAccount from "../../components/Pages/User-Profile/DeleteAccount";
import LoaderUserProfile from "../../components/Loader/LoaderUserProfile";
import classnames from "classnames";

const UserProfile = () => {
  document.title = "HC Kago | Profile";
  const obj = JSON.parse(localStorage.getItem("authHC"));
  const userData = obj?.data?.data;
  const userID = obj?.data?.data?.id;
  const userId = obj?.data?.data?.id;
  const token = obj?.token;
  const [activeTab, setactiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const {
    data: profileInfo,
    error,
    isLoading,
  } = useCustomFetchQuery(
    ["profileInfo", userID],
    `${process.env.REACT_APP_APIKEY}users/${userID}`,
    token,
    { enabled: !!userID }
  );

  //Kea remember to update this api by adding the picture to it
  //then pass it to the view profile component
  // console.log("profileInfo...", JSON.stringify(profileInfo, null, 2));

  // Simulate loading effect
  // useEffect(() => {
  //   // Simulate an API call or data fetching
  //   const timer = setTimeout(() => {
  //     setIsLoading(false); // Set loading to false after 2 seconds
  //   }, 2000);

  //   // Clean up the timer when the component unmounts
  //   return () => clearTimeout(timer);
  // }, []);

  if (isLoading) {
    return <LoaderUserProfile />;
  }

  const isAdmin = userData?.roles?.some((role) => role.type === "Admin");

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ userSelect: "none", background: "#F2F2F2" }}
      >
        <Container fluid>
          <Breadcrumb title="HC Kago" breadcrumbItem="Profile" />
          <div className="w-100 d-flex">
            <ViewProfile userDetails={userData} />
            <div className="cProfile-container">
              <div>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Profile
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Password
                    </NavLink>
                  </NavItem>
                  {isAdmin ? (
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        Account Settings
                      </NavLink>
                    </NavItem>
                  ) : null}
                </Nav>
              </div>
              <div>
                <TabContent activeTab={activeTab} className="p-3">
                  <TabPane tabId="1" id="profile">
                    <Row>
                      <Col sm="12">
                        <UserPersonalInfo userData={userData} token={token} />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2" id="password">
                    <Row>
                      <Col sm="12">
                        <div className="profile-form-title h3 fw-bolder">
                          Change Password
                        </div>
                        <UpdatePassword userData={userData} token={token} />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3" id="account">
                    <Row>
                      <Col sm="12">
                        {isAdmin ? (
                          <>
                            <div className="profile-form-title h3 fw-bolder">
                              Delete Account
                            </div>
                            <DeleteAccount userId={userId} token={token} />
                          </>
                        ) : null}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
