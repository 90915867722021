import React from "react";
import {
  managerCard,
  managerCardWrapper,
  managerCardTitle,
  managerCardTasks,
} from "./Style";

const ManangerTopMenu = () => {
  return (
    <div className="w-100 d-flex gap-4 mb-5">
      <div style={managerCard}>
        <div style={managerCardWrapper}>
          <div style={managerCardTitle}>Due Review(s)</div>
          <div style={{ ...managerCardTasks, color: "#fcb92c" }}>100</div>
        </div>
      </div>
      <div style={managerCard}>
        <div style={managerCardWrapper}>
          <div style={managerCardTitle}>Outstanding Review(s)</div>
          <div style={{ ...managerCardTasks, color: "#ff0000" }}>60</div>
        </div>
      </div>

      <div style={managerCard}>
        <div style={managerCardWrapper}>
          <div style={managerCardTitle}>Closed Review(s)</div>
          <div style={{ ...managerCardTasks, color: "#08b36c" }}>20</div>
        </div>
      </div>
    </div>
  );
};

export default ManangerTopMenu;
