import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomCheckbox = styled(Checkbox)({
  color: "#33a6cd",
  "&.Mui-checked": {
    color: "#33a6cd",
  },
  "& .MuiSvgIcon-root": {
    borderColor: "#33a6cd",
  },
  "&:not(.Mui-checked) .MuiSvgIcon-root": {
    border: `1px solid #33a6cd`,
    borderRadius: "2px",
  },
});

const UserPasswordForm = ({
  inputs,
  isChecked,
  setIsChecked,
  register,
  errors,
}) => {
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      <Row className="w-100" style={{ height: "250px" }}>
        <Col md={6}>
          <label>
            Password <span className="text-danger">*</span>
          </label>
          <input
            type="password"
            name="password"
            value={inputs.password}
            placeholder="Enter password"
            style={{ borderRadius: 7 }}
            className={`mb-3 form-control fromInput ${
              errors.password ? "border border-danger" : ""
            }`}
            {...register("password")}
          />
          {errors.password && (
            <p className="text-danger">{errors.password.message}</p>
          )}
        </Col>
        <Col md={6}>
          <label>
            Confirm password <span className="text-danger">*</span>
          </label>
          <input
            type="password"
            name="passwordConfirm"
            value={inputs.passwordConfirm}
            placeholder="Enter confirm password"
            style={{ borderRadius: 7 }}
            className={`mb-3 form-control fromInput ${
              errors.passwordConfirm ? "border border-danger" : ""
            }`}
            {...register("passwordConfirm")}
          />
          {errors.passwordConfirm && (
            <p className="text-danger">{errors.passwordConfirm.message}</p>
          )}
        </Col>
        <Col md={12}>
          <div className="w-100 mt-2 mb-2 d-flex align-items-center">
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
              }
              label={
                <Typography variant="body2" className="mt-2 text-dark">
                  By Agreeing you accept to receive noitification and email
                  marketing for Kago Human Capital.
                </Typography>
              }
              style={{ alignItems: "start" }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UserPasswordForm;
