import React, { useState, useEffect, useRef } from "react";
import usePost from "../../../lib/Hooks/usePost";
import { Row, Col } from "reactstrap";
import { TiCameraOutline } from "react-icons/ti";
import { LiaUser } from "react-icons/lia";
import ButtonSubmit from "../../Common/Buttons/ButtonSubmit";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function UserPersonalInfo({ userData, token }) {
  const { execute, pending, data, status } = usePost();
  const [showImage, setShowImage] = useState(null);
  const [uploadImg, setUploadImg] = useState(null);
  const [inputs, setInputs] = useState({});
  const fileInputRef = useRef(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const Method = "PATCH",
      url = `users/updateMe`,
      isJSON = true;
    var formdata = new FormData();
    if (uploadImg) {
      formdata.append("photo", uploadImg);
    }
    Object.entries(inputs).forEach(([key, value]) => {
      if (value) formdata.append(key, value);
    });

    execute(
      url,
      formdata,
      Method,
      "Profile successfully updated",
      token,
      isJSON
    );
  };

  useEffect(() => {
    if (status === "success") {
      const user = data?.data;
      if (user) {
        localStorage.clear();
        localStorage.setItem("authHC", JSON.stringify(user));
      }
    }
    if (userData) {
      const { photo, firstName, lastName, email, phoneNumber } = userData;
      setInputs({
        firstName,
        lastName,
        email,
        phoneNumber,
      });
      setShowImage(photo);
    }
  }, [status, userData]);

  const inputRef = useRef(null);
  const imageHandler = (e) => {
    let files = Array.from(e.target.files);
    files.forEach((file) => {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/gif" &&
        file.type !== "image/webp" &&
        file.type !== "video/mp4" &&
        file.type !== "video/mpeg" &&
        file.type !== "image/webm"
      ) {
        files = files.filter((item) => item.name !== file.name);
        return;
      } else if (file.size > 1024 * 1024 * 10) {
        files = files.filter((item) => item.name !== file.name);
        return;
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          setShowImage(e.target.result);
          setUploadImg(file);
        };
      }
    });
  };

  // console.log("inputs..", JSON.stringify(inputs, null, 2));
  return (
    <div className="w-100 d-flex justify-content-center mt-2">
      <form className="form-create-event" onSubmit={handleSubmitForm}>
        <Row>
          <Col md={12}>
            <div className="uProfile-container">
              {showImage ? (
                <img src={showImage} alt="profile" />
              ) : (
                <div className="uProfile-container-icon">
                  <LiaUser size={100} color="#fff" />
                </div>
              )}
              <div className="uProfile-container-uploader">
                <span
                  onClick={() => {
                    inputRef.current.click();
                  }}
                >
                  <TiCameraOutline size={20} color="#7c7c7c" />
                </span>
              </div>
              <input
                type="file"
                hidden
                multiple
                ref={inputRef}
                accept="image/png,image/jpeg,image/gif,image/webp,video/mp4,video/mpeg"
                onChange={imageHandler}
              />
            </div>
          </Col>
          <Col md={6}>
            <label className="event__input__form__Creation">First name </label>
            <input
              type="text"
              name="firstName"
              className="mb-3 form-control fromInput w-100"
              value={inputs?.firstName}
              onChange={handleChange}
              required
            />
          </Col>
          <Col md={6}>
            <label className="event__input__form__Creation">Last name</label>
            <input
              type="text"
              name="lastName"
              className="mb-3 form-control fromInput w-100"
              value={inputs?.lastName}
              onChange={handleChange}
              required
            />
          </Col>
          <Col md={6}>
            <label className="event__input__form__Creation">Email</label>
            <input
              type="email"
              name="email"
              className="mb-3 form-control fromInput w-100"
              value={inputs?.email}
              onChange={handleChange}
              disabled
            />
          </Col>
          <Col md={6}>
            <label>Phone Number</label>
            <div className="phone-input-container">
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="ZA"
                value={inputs.phoneNumber ? inputs.phoneNumber : null}
                onChange={(value) =>
                  setInputs({ ...inputs, phoneNumber: value })
                }
                className="custom-phone-input"
              />
            </div>
          </Col>
          <Col md={12}>
            <label>Bio </label>
            <textarea
              name="bio"
              value={inputs?.bio}
              onChange={handleChange}
              className="w-100 p-2 rounded-3 form-control fromInput"
              rows="4"
              required
            />
          </Col>

          <div className="d-flex justify-content-center align-item-center mt-3">
            <ButtonSubmit
              Title="Update"
              BackgroundColor="#33A6CD"
              ColorText="white"
              BorderColor="#33A6CD"
              borderRadius={20}
              handleOnclick={handleSubmitForm}
              pending={pending}
            />
          </div>
        </Row>
      </form>
    </div>
  );
}

export default UserPersonalInfo;
