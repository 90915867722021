import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";
import EmployeeDetails from "../Pages/People/Employees/EmployeeDetailsCopy";
import EmployeePerformance from "../Pages/People/Employees/EmployeePerformance";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import Otp from "../Pages/Authentication/Otp";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import MessageSent from "../components/Pages/Authentication/MessageSent";
import Reset from "../Pages/Authentication/Reset";
// import Logout from "../Pages/Authentication/Logout";
// import Register from "../Pages/Authentication/Register";
import Pricing from "../Pages/Pricing/Pricing";
// import CreateAccount from "../components/Pages/Authentication/CreateAccount";
import CreateAccount from "../Pages/Authentication/CreateAccountPage";
import Message from "../Pages/Authentication/Message";
import VerifyPage from "../Pages/Authentication/VerifyPage";
import Checkout from "../Pages/Checkout/Checkout";
// import OwnerRegistration from "../Pages/Authentication/Register";
import AdminRegistration from "../components/Pages/Authentication/AdminRegistration";

import UserProfile from "../Pages/Authentication/user-profile";
import AllEmployees from "../Pages/People/Employees/AllEmployees";
import AllManagers from "../Pages/People/Managers/AllManagers";
import ReviewForm from "../Pages/ReviewForm/ReviewForm";
import ManagerReviewForm from "../Pages/ReviewForm/ManagerReviewForm";
import OrganizationSettings from "../Pages/OrganizationSettings/Index";
import MySubscription from "../Pages/MySubscriptions/MySubscription";
import MessageReset from "../components/Pages/Authentication/MessageReset";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/userprofile", component: <UserProfile /> },
  { path: "/managers", component: <AllManagers /> },
  { path: "/employees", component: <AllEmployees /> },
  { path: "/employee-details", component: <EmployeeDetails /> },
  { path: "/employee/:ID", component: <EmployeePerformance /> },
  { path: "/employee/review", component: <ReviewForm /> },
  { path: "/employee-review", component: <ManagerReviewForm /> },
  { path: "/organization-settings", component: <OrganizationSettings /> },
  { path: "/subscriptions", component: <MySubscription /> },
  { path: "/review/:ID", component: <ManagerReviewForm /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  {
    path: "/logout",
    exact: true,
    component: <Navigate to="/login" />,
  },
  { path: "/login", component: <Login /> },
  { path: "/otp", component: <Otp /> },
  { path: "/resetPassword/:token", component: <Reset /> },
  { path: "/reset", component: <MessageReset /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/sent-email", component: <MessageSent /> },

  { path: "/pricing", component: <Pricing /> },
  { path: "/create-account", component: <CreateAccount /> },
  { path: "/message", component: <Message /> },
  { path: "/Verify/:userID/:token", component: <VerifyPage /> },
  // { path: "/register", component: <Register /> },
  // { path: "/register", component: <OwnerRegistration /> },

  { path: "/checkout", component: <Checkout /> },
  { path: "/register", component: <AdminRegistration /> },
];

export { authProtectedRoutes, publicRoutes };
