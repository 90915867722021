import React, { useState, useEffect } from "react";
import useCustomMutation from "../../../../lib/Hooks/useCustomMutation";
import useCustomFetchQuery from "../../../../lib/Hooks/useCustomFecthQuery";
import { Card, CardBody } from "reactstrap";
import DataTable from "react-data-table-component";
import { AiFillEye } from "react-icons/ai";
// import { FaEdit } from "react-icons/fa";
// import { MdMoreVert } from "react-icons/md";
import { LuUser2 } from "react-icons/lu";
import SearchInput from "../../../Common/SearchIput";
import { ButtonBtn } from "../../../Common/Buttons/index";
import { employeeTblTitle, employeeTbl } from "../../../Common/TableStyle";

import OffCanvas from "../../../Common/OffCanvas/OffCanvas";
import EmployeeForm from "./EmployeeForm";
// import EditEmployeeForm from "./EditEmployeeForm";
import EmployeeDetails from "../../../../Pages/People/Employees/EmployeeDetails";

const AllEmployeesTable = ({
  token,
  organizationID,
  organizationDetails,
  departmentDetails,
  packageActivation,
}) => {
  const [search, setSearch] = useState("");
  const [isAddProfile, setIsAddProfile] = useState(false);
  const [isViewProfile, setIsViewProfile] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState(null);

  const key = ["AllEmployees", organizationID];
  const mutation = useCustomMutation(key);

  const {
    data: getEmployees,
    error,
    isLoading,
  } = useCustomFetchQuery(
    ["AllEmployees", organizationID],
    `${process.env.REACT_APP_APIKEY}employees?oid=${organizationID}`,
    token,
    { enabled: !!organizationID }
  );
  // Filtering and sorting the data array
  const filteredEmployeesData =
    getEmployees?.data
      .filter((employee) =>
        employee?.roles?.some((role) =>
          ["Learner", "Intern", "Employee"].includes(role?.type)
        )
      )
      .sort((a, b) => a?.firstName?.localeCompare(b?.firstName)) || [];

  const toggleView = (employee) => {
    setIsViewProfile(!isViewProfile);
    setEmployeeDetails(employee);
  };

  const toggleAddOffCanvas = () => {
    setIsAddProfile(!isAddProfile);
  };
  const toggleViewOffCanvas = () => {
    setIsViewProfile(!isViewProfile);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Employee Name</span>,
      cell: (row) => {
        return (
          <div className="w-100">
            <div className="d-flex align-items-center gap-2">
              <div
                className="d-flex justify-content-center align-items-center bg-primary"
                style={{ width: 35, height: 35, borderRadius: "50%" }}
              >
                {row?.photo ? (
                  <img
                    src={row?.photo}
                    alt="employee-pic"
                    className="w-100 h-100"
                    style={{ borderRadius: "50%" }}
                  />
                ) : (
                  <LuUser2 color="white" size={19} />
                )}
              </div>
              <div>
                <div style={{ fontSize: 14, fontWeight: 400 }}>
                  {row?.firstName} {row?.lastName}
                </div>
                <div
                  style={{ fontSize: 12, fontWeight: 500, color: "#A7A7A7" }}
                >
                  {row?.email.length < 27
                    ? row?.email
                    : `${row?.email?.substring(0, 28)}...`}
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Department</span>,
      selector: (row) => row?.departmentId?.name,
    },
    {
      name: <span className="font-weight-bold fs-13">Role</span>,
      selector: (row) => row?.roles[0]?.type,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <AiFillEye
                size={20}
                className="mx-1"
                onClick={() => toggleView(row)}
              />
            </div>
            {/* <div>
              <FaEdit
                size={16}
                className="mx-1"
                onClick={() => toggleEdit(row?.id)}
              />
            </div> */}
          </div>
        );
      },
    },
  ];

  const filterEmployee = (employeeData, searchQuery) => {
    if (!searchQuery) return employeeData;
    return employeeData?.filter((item) =>
      [
        item?.firstName,
        item?.lastName,
        item?.departmentId?.name,
        item?.role,
        item?.position,
      ].some((field) =>
        field?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };
  const filteredEmployeeTable = filterEmployee(filteredEmployeesData, search);

  const data = filteredEmployeeTable || [];

  useEffect(() => {
    if (mutation?.isSuccess) {
      // setIsOffcanvasOpen(false);
    }
  }, [mutation?.isSuccess]);

  // packageActivation;

  return (
    <div className="mt-3 mb-5 w-100">
      <div className="w-100 mb-3 d-flex justify-content-between">
        <SearchInput
          Title={"Search"}
          search={search}
          setSearch={setSearch}
          radius={20}
        />
        <div style={{ fontSize: 18, fontWeight: "bolder" }}>
          <ButtonBtn
            Title="Add Employee"
            type="button"
            BackgroundColor="#33A6CD"
            ColorText="white"
            BorderColor="#33A6CD"
            borderRadius={20}
            handleOnclick={toggleAddOffCanvas}
            pending={false}
          />
        </div>
      </div>
      <Card style={employeeTbl}>
        <CardBody>
          <div style={employeeTblTitle}>Employees</div>
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="300px"
            columns={columns}
            responsive
            data={data}
            pagination
          />
        </CardBody>
      </Card>
      <OffCanvas
        isOffcanvasOpen={isAddProfile}
        title="Add Employee"
        body={
          <EmployeeForm
            token={token}
            setIsAddProfile={setIsAddProfile}
            organizationID={organizationID}
            organizationDetails={organizationDetails}
            departmentDetails={departmentDetails}
          />
        }
        toggleSaveChanges={() => toggleAddOffCanvas()}
        directionOffCanvas="end"
        widthOffCanvas="100%"
      />

      <OffCanvas
        isOffcanvasOpen={isViewProfile}
        title="View Employee"
        body={<EmployeeDetails employeeDetails={employeeDetails} />}
        toggleSaveChanges={() => toggleViewOffCanvas()}
        directionOffCanvas="end"
        widthOffCanvas="100%"
      />
      {/* <OffCanvas
        isOffcanvasOpen={isViewProfile}
        title="View Employee"
        body={
          <EditEmployeeForm
            employeeId={employeeId}
            departmentDetails={departmentDetails}
          />
        }
        toggleSaveChanges={() => toggleViewOffCanvas()}
        directionOffCanvas="end"
        widthOffCanvas="100%"
      /> */}
    </div>
  );
};
export default AllEmployeesTable;
