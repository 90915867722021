import React, { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useCustomMutation from "../../../../lib/Hooks/useCustomMutation";
import usePost from "../../../../lib/Hooks/usePost";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerEmployeeSchema } from "../../../../lib/utils/dataValidation";
import { Row, Col } from "reactstrap";
import Select from "react-select";
import { countryList } from "../../../../lib/utils/Countries";
import ButtonSubmit from "../../../Common/Buttons/ButtonSubmit";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import PhotoPlaceholder from "../../../Common/Photo/PhotoPlaceholder";

const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#33A6CD" : "white",
    color: state.isFocused ? "white" : "black",
  }),
  control: (provided) => ({
    ...provided,
    borderColor: "#ccc",
    "&:hover": {
      borderColor: "#33A6CD",
    },
  }),
};

function ManagerForm({
  token,
  organizationID,
  setIsAddProfile,
  organizationDetails,
  departmentDetails,
}) {
  const queryClient = useQueryClient();
  const { status, execute, pending } = usePost();
  const [inputs, setInputs] = useState({ phoneNumber: 0 });
  const key = ["AllManagers", organizationID];
  const mutation = useCustomMutation(key);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(registerEmployeeSchema) });
  const onSubmit = async (data) => {
    const Method = "POST",
      url = `auth/employee/${organizationID}/${inputs.department}`;

    const raw = JSON.stringify({
      ...data,
      roles: inputs.roles[0],
      phoneNumber: inputs.phoneNumber,
    });
    await execute(url, raw, Method, "Manager successfully added", token, null);
  };

  const handleChange = (eventOrName, valueOrUndefined) => {
    if (typeof eventOrName === "string") {
      const name = eventOrName;
      const value = valueOrUndefined;
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    } else {
      const { name, value } = eventOrName.target;
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    }
  };

  // const toggleSaveChanges = (e) => {
  //   e.preventDefault();
  //   const Method = "POST",
  //     url = `auth/employee/${organizationID}/${inputs?.department}`;
  //   const raw = JSON.stringify({
  //     employeeNumber: inputs.employeeNumber,
  //     firstName: inputs.firstName,
  //     lastName: inputs.lastName,
  //     email: inputs.email,
  //     phoneNumber: inputs.phoneNumber,
  //     roles: inputs.roles[0],
  //     initials: inputs.initials,
  //     dateOfBirth: inputs.dateOfBirth,
  //     identificationNumber: inputs.identificationNumber,
  //     country: inputs.country,
  //     taxNumber: inputs.taxNumber,
  //     physicalAddress: inputs.physicalAddress,
  //     unitNumber: inputs.unitNumber,
  //     complex: inputs.complex,
  //     suburb: inputs.suburb,
  //     city: inputs.city,
  //     postalCode: inputs.postalCode,
  //   });
  //   // const data = {
  //   //   endPoint: url,
  //   //   bodyContent: raw,
  //   //   Method: Method,
  //   //   displayMessage: "Manager successfully added",
  //   //   token: token,
  //   // };
  //   execute(url, raw, Method, "Manager successfully added", token, null);
  //   // mutation.mutate(data);
  // };

  useEffect(() => {
    if (status) {
      queryClient.invalidateQueries(key);
      setIsAddProfile(false);
    }
  }, [mutation?.isSuccess, status]);

  return (
    <>
      <div className="w-100 d-flex justify-content-center p-3">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col md={12}>
              <div className="d-flex mb-4 justify-content-center">
                <PhotoPlaceholder
                  iconSize={80}
                  iconColor="#fff"
                  imgHeight={100}
                  imgWidth={100}
                  imgBorder={20}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={4}>
              <label>
                Department <span className="text-danger">*</span>
              </label>
              <Select
                options={departmentDetails?.map((item) => ({
                  value: item?._id,
                  label: item?.name,
                }))}
                styles={customSelectStyles}
                placeholder="Select a department"
                onChange={(option) => handleChange("department", option?.value)}
                // {...register("department")}
              />
              {/* {errors.department && (
                <p className="text-danger">{errors.department.message}</p>
              )} */}
            </Col>
            <Col md={6} lg={4}>
              <label>
                Roles <span className="text-danger">*</span>
              </label>
              <Select
                options={organizationDetails?.roles?.map((role) => ({
                  value: JSON.stringify({
                    type: role?.name,
                    period: role?.period,
                  }),
                  label: role?.name,
                }))}
                styles={customSelectStyles}
                placeholder="Select a role"
                onChange={(option) =>
                  handleChange("roles", [JSON.parse(option?.value)])
                }
                // {...register("roles")}
              />
              {/* {errors.roles && (
                <p className="text-danger">{errors.roles.message}</p>
              )} */}
            </Col>

            <Col md={6} lg={4}>
              <label>
                Employee number <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="employeeNumber"
                value={inputs.employeeNumber}
                // onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                // {...register("employeeNumber")}
              />
              {/* {errors.employeeNumber && (
                <p className="text-danger">{errors.employeeNumber.message}</p>
              )} */}
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={4}>
              <label>
                Initials <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="initials"
                value={inputs.initials}
                // onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                {...register("initials")}
              />
              {errors.initials && (
                <p className="text-danger">{errors.initials.message}</p>
              )}
            </Col>

            <Col md={6} lg={4}>
              <label>
                First name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="firstName"
                value={inputs.firstName}
                placeholder="Enter First name"
                // onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                {...register("firstName")}
              />
              {errors.firstName && (
                <p className="text-danger">{errors.firstName.message}</p>
              )}
            </Col>
            <Col md={6} lg={4}>
              <label>
                Last name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="lastName"
                value={inputs.lastName}
                placeholder="Enter Last name"
                // onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                {...register("lastName")}
              />
              {errors.lastName && (
                <p className="text-danger">{errors.lastName.message}</p>
              )}
            </Col>
            <Col md={6} lg={4}>
              <label>
                Date of birth <span className="text-danger">*</span>
              </label>
              <input
                type="date"
                name="dateOfBirth"
                value={inputs?.dateOfBirth}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                {...register("dateOfBirth")}
              />
              {errors.dateOfBirth && (
                <p className="text-danger">{errors.dateOfBirth.message}</p>
              )}
            </Col>
            <Col md={6} lg={4}>
              <label>
                ID number / Passport <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="identificationNumber"
                value={inputs?.identificationNumber}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                {...register("identificationNumber")}
              />
              {errors.identificationNumber && (
                <p className="text-danger">
                  {errors.identificationNumber.message}
                </p>
              )}
            </Col>

            <Col md={6} lg={4}>
              <label>
                Country of Origin <span className="text-danger">*</span>
              </label>
              <Select
                options={countryList.map((country) => ({
                  value: country,
                  label: country,
                }))}
                styles={customSelectStyles}
                placeholder="Select..."
                onChange={(option) =>
                  handleChange("countryOfOrigin", option.value)
                }
                // {...register("countryOfOrigin")}
              />
              {/* {errors.countryOfOrigin && (
                <p className="text-danger">{errors.countryOfOrigin.message}</p>
              )} */}
            </Col>
            <Col md={6} lg={4}>
              <label>
                Tax number
                {/* <span className="text-danger">*</span> */}
              </label>
              <input
                type="text"
                name="taxNumber"
                value={inputs?.taxNumber}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
              />
            </Col>
            <Col md={6} lg={4}>
              <label>
                Email <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="email"
                value={inputs?.email}
                placeholder="Enter Email"
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                {...register("email")}
              />
              {errors.email && (
                <p className="text-danger">{errors.email.message}</p>
              )}
            </Col>
            <Col md={6} lg={4}>
              <label>
                Phone Number <span className="text-danger">*</span>
              </label>
              <div
                className={`phone-input-container ${
                  inputs.phoneNumber.length < 12 && inputs.phoneNumber !== null
                    ? "border border-danger"
                    : ""
                }`}
              >
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="ZA"
                  value={inputs.phoneNumber ? inputs.phoneNumber : null}
                  onChange={(value) =>
                    setInputs({ ...inputs, phoneNumber: value ? value : 0 })
                  }
                  className="custom-phone-input"
                />
              </div>
              {inputs.phoneNumber.length < 12 && inputs.phoneNumber !== null ? (
                <p className="text-danger"> Required valid phone number </p>
              ) : null}
            </Col>
            <Col md={12}>
              <h3 className="my-3 d-flex justify-content-center">
                Residential address
              </h3>
            </Col>
            <Col md={6} lg={4}>
              <label>
                Physical address <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="physicalAddress"
                value={inputs?.physicalAddress}
                // onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                {...register("physicalAddress")}
              />
              {errors.physicalAddress && (
                <p className="text-danger">{errors.physicalAddress.message}</p>
              )}
            </Col>
            <Col md={6} lg={4}>
              <label>Unit number</label>
              <input
                type="text"
                name="unitNumber"
                value={inputs?.unitNumber}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
              />
            </Col>
            <Col md={6} lg={4}>
              <label>Complex</label>
              <input
                type="text"
                name="complex"
                value={inputs?.complex}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
              />
            </Col>
            <Col md={6} lg={4}>
              <label>
                Suburb / district <span className="text-danger">*</span>
              </label>

              <input
                type="text"
                name="suburb"
                value={inputs?.suburb}
                // onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                {...register("suburb")}
              />
              {errors.suburb && (
                <p className="text-danger">{errors.suburb.message}</p>
              )}
            </Col>
            <Col md={6} lg={4}>
              <label>
                City / Town <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="city"
                value={inputs?.city}
                // onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                {...register("city")}
              />
              {errors.city && (
                <p className="text-danger">{errors.city.message}</p>
              )}
            </Col>
            <Col md={6} lg={4}>
              <label>
                Postal code <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="postalCode"
                value={inputs?.postalCode}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                {...register("postalCode")}
              />
              {errors.postalCode && (
                <p className="text-danger">{errors.postalCode.message}</p>
              )}
            </Col>

            <Col md={6} lg={4}>
              <label>
                Country <span className="text-danger">*</span>
              </label>
              <Select
                options={countryList.map((country) => ({
                  value: country,
                  label: country,
                }))}
                styles={customSelectStyles}
                placeholder="Select..."
                onChange={(option) => handleChange("country", option.value)}
                // {...register("country")}
              />
              {/* {errors.country && (
                <p className="text-danger">{errors.country.message}</p>
              )} */}
            </Col>

            <div className="mb-3"></div>
            <div className="w-100 d-flex justify-content-center">
              <ButtonSubmit
                Title="Submit"
                ColorText="white"
                BorderColor="#33A6CD"
                BackgroundColor="#33A6CD"
                borderRadius="25px"
                // handleOnclick={toggleSaveChanges}
                pending={pending}
              />
            </div>
          </Row>
        </form>
      </div>
    </>
  );
}

export default ManagerForm;
